export const NodeShape: any = {
    video: {
      shape: 'icon',
      // color: 'blue',
      icon: {
        face: 'FontAwesome',
        code: '\uf03d',
        size: 50,
        color: 'blue'
      }
    },
    assessment: {
      shape: 'icon',
      // color: 'purple',
      icon: {
        face: 'FontAwesome',
        code: '\uf15c',
        size: 50,
        color: 'purple'
      }
    },
    group: {
      shape: 'icon',
      // color: 'orange',
      icon: {
        face: 'FontAwesome',
        code: '\uf0c0',
        size: 50,
        color: 'orange'
      }
    },
    individual: {
      shape: 'icon',
      // color: 'black',
      icon: {
        face: 'FontAwesome',
        code: '\uf0f0',
        size: 50,
        color: 'black'
      }
    },
    decision: {
      shape: 'diamond',
      color: 'sky_blue'
    },
    task: {
        // shape: 'icon',
        // icon: {
        //   face: '"Font Awesome 5 Free"',
        //   code: '\uf46d',
        //   size: 50,
        //   color: 'red'
        // }
        shape: 'image',
        image: '../../../assets/images/clipboard-list.png',
        // icon: {
        //   face: '"FontAwesome"',
        //   code: '\uf0ae',
        //   size: 50,
        //   color: 'red'
        // }
    },
    service: {
      shape: 'image',
      image: '../../../assets/images/clipboard-list.png',
  },
};

export const Colors: any = {
  blue: {
    border: '#20e8e9',
    background: '#acfffc'
  },
  orange: {
    border: '#e96c1c',
    background: '#ffa95d'
  },
  purple: {
    border: '#d60de9',
    background: '#fc90f2'
  },
  green: {
    border: '#04e900',
    background: '#b4fc8f'
  },
  red: {
    border: '#e9292d',
    background: '#ff7b7e'
  },
  black: {
    border: '#000',
    background: '#000'
  },
  sky_blue: {
    border: '#347CAE',
    background: '#52B4F8'
  },
  blue_edge: {
    color: '#347CAE',
    highlight: '#347CAE',
    hover: '#347CAE'
  },
  default_edge: {
    color: '#333333',
    highlight: '#848484',
    hover: '#848484'
  },
  preemptive_color : {
    color: '#00ff00'
  },
  non_preemptive_color: {
    color: '#FF0000'
  }
};

const start_id = 0;
const end_id = 9999;
export const InitialNodeData = [
  {
    id: start_id,
    label: 'Start',
    activity_name: 'Start',
    type: 'start',
    shape: 'circle',
    margin: 10,
    borderWidth: 1,
    color: Colors.green,
    font: { color: '#000' },
    physics: false,
    x: -300,
    y: 0
    // fixed: {
    //   x: true,
    //   y: true
    // },
  },
  {
    id: end_id,
    label: 'End',
    activity_name: 'End',
    type: 'end',
    shape: 'circle',
    color: {
      border: '#e9292d',
      background: '#ff7b7e'
    },
    margin: 10,
    physics: false,
    font: { color: '#000' },
    x: 300,
    y: 150
    // fixed: {
    //   x: true,
    //   y: true
    // },
  }
];

export const InitialEdgeData = [];