import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isNumeric } from "rxjs/util/isNumeric"

export class CustomValidators implements OnInit {

    static birthYear(c: FormControl): ValidationErrors  {
        const user_dob = new Date(c.value);

        let currentDate = new Date();
        let lastDate = new Date();
        currentDate =  new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
        lastDate = new Date(lastDate.getFullYear() - 90, lastDate.getMonth(), lastDate.getDate());
        const isValid = (currentDate >= user_dob && lastDate <= user_dob);
        const message = {
          'years': {
            'message': 'Minimum age should be 18 years'
          }
        };
        return isValid ? null : message;
      }


     static mobileNo(c: FormControl): ValidationErrors {
     // let isValid = false;
     const mobile_number = c.value;
     const isValid = (mobile_number.toString().length == 9 && mobile_number != null);
     const message = {
       'message': 'Enter 9-digit number'
     };
     return isValid ? null : message;
   }
   static mobileNo_10(c: FormControl): ValidationErrors {
    // let isValid = false;
      const mobile_number = c.value;
      const isValid = (mobile_number != null && isNumeric(mobile_number) && mobile_number.toString().length == 10);
      const message = {
        'mobileNo_10': {
          'message':'Enter 10-digit number'
        }
      };

      return isValid ? null : message;
    }

   static startDate(c: FormControl): ValidationErrors {
     const date = new Date(c.value);
     const today = new Date();
     const isValid =  (date.getTime() < today.getTime()) ? true : false;
     const message = {
      'message': ' Start date should be a date after today'
      };
      return isValid ? null : message;
   }

  static start_date_for_org(c: FormControl): ValidationErrors {
    const date = new Date(c.value);
    const today = new Date();
    const isValid = (date <= today) ? true : false;
    const message = {
      'start_date_org': {
        'message': 'Start date should be present date or a back date'
      }
    };
    return isValid ? null : message;
  }

    static ID_no_format(c: FormControl): ValidationErrors {
      var cc = c.value;
      var isValid = true;
      var message = {};
      if(cc != ''){
        const d = new Date('19'+cc.substring(0,2)+'-'+cc.substring(2,4)+'-'+cc.substring(4,6));
        const x = (d.toString() == 'Invalid Date');
        // YYMMDDSSSSCAZ
        let nSum: number = 0;
        let isSecond = false;
        for (let i = cc.length - 1; i >= 0; i--) {
          let d = Number(cc[i]);
          if (isSecond == true) {
            d = d * 2;
          }
          nSum += Math.floor(d / 10);
          nSum += Math.floor(d % 10);
          isSecond = !isSecond;
        }

        if(cc.length != 13 || isNaN(cc) || x || (cc.substring(10,11) != '0' && cc.substring(10,11) != '1') || Math.floor(nSum % 10 )!= 0 ){
            isValid = false;
            message = {
              'idNumber': {
                'message': 'ID Number format Incorrect. YYMMDDSSSSCAZ'
              }
            };
        }
      }
      return isValid ? null : message;
    }

    static isNotExemptionDependentDate(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);
      const isValid =  (age_value > 21) ? false : true;
      const message = {
       "isNotExemptionDependentDate":{
          'message': ' Age cannot be greater than 21'
        }
       };
       return isValid ? null : message;
    }

    static isExemptionDependentDate(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);

      const isValid =  (age_value > 26) ? false : true;
      const message = {
       "isExemptionDependentDate":{
          'message': ' Age cannot be greater than 26'
        }
       };
       return isValid ? null : message;
    }

    static adultAge(c: FormControl): ValidationErrors {
      const birthday = new Date(c.value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);

      const isValid =  (age_value < 21) ? false : true;
      const message = {
       "adultAge":{
          'message': ' Age cannot be less than 21'
        }
       };
       return isValid ? null : message;
    }
    ngOnInit() {

    }
}
