import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisConfigService {

  constructor() { }

  options = {};

  flowchart_view_options() {

    this.options = {
      interaction: {
        zoomView: true,
        dragView: true,
        dragNodes: true
      },
      nodes: {
        shape: 'circle',
        color: {
          highlight: {
            border: '#262626',
            background: '#d1d0d3'
          }
        },
      },
      edges: {
        arrows: {
          to: { enabled: true }
        },
        color: {
          color: '#333333',
          inherit: false
        },
        smooth: {
          enabled: false
        }
      },
      physics: true,
      locales: {
        en: {
          addNode: 'Add Activity',
          addEdge: 'Add Sequence',
          edit: 'Edit',
          del: 'Delete selected',
          back: 'Back',
          editNode: 'Edit Node',
          editEdge: 'Edit Edge',
          addDescription: 'Click in an empty space to place a new node.',
          edgeDescription: 'Click on a node and drag the edge to another node to connect them.',
          editEdgeDescription: 'Click on the control points and drag them to a node to connect to it.',
          createEdgeError: 'Cannot link edges to a cluster.',
          deleteClusterError: 'Clusters cannot be deleted.',
          editClusterError: 'Clusters cannot be edited.'
        }
      },
      manipulation: false
    };

    return this.options;

  }

  flowchart_edit_options() { //  VIS with Updates

    this.options = {
      interaction: {
        zoomView: true,
        // dragView: false
      },
      nodes: {
        shape: 'circle',
        color: {
          highlight: {
            border: '#262626',
            background: '#d1d0d3'
          }
        },
      },
      edges: {
        arrows: {
          to: { enabled: true }
        },
        color: {
          color: '#333333',
          inherit: false
        },
        smooth: {
          enabled: false
        }
      },
      physics: {
        barnesHut: {
          gravitationalConstant: -3250,
          springLength: 100
        }
      },
      locales: {
        en: {
          addNode: 'Add Activity',
          addEdge: 'Add Sequence',
          edit: 'Edit',
          del: 'Delete selected',
          back: 'Back',
          editNode: 'Edit Node',
          editEdge: 'Edit Edge',
          addDescription: 'Click in an empty space to place a new node.',
          edgeDescription: 'Click on a node and drag the edge to another node to connect them.',
          editEdgeDescription: 'Click on the control points and drag them to a node to connect to it.',
          createEdgeError: 'Cannot link edges to a cluster.',
          deleteClusterError: 'Clusters cannot be deleted.',
          editClusterError: 'Clusters cannot be edited.'
        }
      },
      manipulation: {}
    };
    return this.options;
  }
}
