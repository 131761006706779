import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../dce-service/env.service';

@Injectable({
  providedIn: 'root'
})
export class DatatableWidgetService {

  constructor(
    private http: HttpClient,
    private env: EnvService) { }

  private _datatable_data = `${this.env.service_url}/`;

  get_datatable_data(service, payload) {
    return this.http.post<any>(this._datatable_data + service, payload);
  }

}
