import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';
import { Md5 } from 'md5-typescript';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from 'src/app/dce-service/toastr-utility.service';

declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  login_details_payload: FormGroup;
  submitted = false;
  valid_password = true;
  @Input() private user_id: Number;
  constructor(
    private _fb: FormBuilder,
    private _common_service: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,

  ) { }

  ngOnInit() {
    this.initialize_payload()
  }
  initialize_payload(){
    this.login_details_payload = this._fb.group({
      'login_password': this._fb.control('',Validators.required),
      'new_password': this._fb.control('', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]),
      'cpassword': this._fb.control('', [Validators.required]),
    });
  }
  validate_password(){
    this.submitted = true;
    let login_detail = this.login_details_payload.value;
    if (this.login_details_payload.status == 'VALID' || this.login_details_payload.status == 'DISABLED') {
      if(login_detail['new_password'] == login_detail['cpassword']){
        this.valid_password = true;
        return true;
      }else{
        this.valid_password = false;
      }
    }
    return false;
  }
  update_password(){
    if(this.validate_password()){
      let login_detail = this.login_details_payload.value;
      const payload = {
        'admin_password':Md5.init(Md5.init(login_detail['login_password'])),
        'for_user_id': this.user_id,
        'for_user_password':Md5.init(Md5.init(login_detail['new_password'])),
      }
      this._common_service.change_user_password_by_admin(payload).subscribe(res => {
        if(res.errCode == 0){
          this._toastr.success('',res.msg, this._toastrUtility.basic_configuration);
          this.close_modal();
        }
      });
    }

  }
  
  clear_payload() {
    this.login_details_payload.reset();
  }

  open_modal() {
    this.clear_payload();
    this.submitted = false;
    $('#popup').modal();
  }

  close_modal() {
    $("#popup").modal('hide');    
  }
}
