import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UploadDocumentComponent } from '../upload-document/upload-document.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { AddLogsService } from './add-logs.service';

declare var $: any;

@Component({
  selector: 'app-log-details',
  templateUrl: './add-logs.component.html',
  styleUrls: ['./add-logs.component.scss']
})
export class AddLogsComponent implements OnInit {

  @Input() log_type = []; // ['note', 'email']
  @Input() log_type_case = ''
  @Input() user_signature?: string = '';

  @Output() log_details_action_event: EventEmitter<any> = new EventEmitter<any>();

  show_note_box = false;
  note_text = '';
  current_log_type = '';

  log_email_value = '';
  is_signature = false;
  email_body: string;
  email_subject: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'size': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image']
    ]
  };
  modules_readonly = {toolbar: false };
  email_log_type = false;
  note_log_type = false;

  document_list = [];
  moduleLoading = false;
  log_email_cc_value = '';
  email_body_empty = false;
  email_subject_empty = false; 

  @ViewChild(UploadDocumentComponent, { static: false }) uploadDocumentComponent: UploadDocumentComponent;

  // if logtype is email
  @Input()
  set email_value(val: string) {
    this.log_email_value = val;
    this.log_email_cc_value = '';
  }
  get email_value(): string { return this.log_email_value; }

  @Input()
  set signature_bool(val: boolean) {
    this.is_signature = val;
  }
  get signature_bool(): boolean { return this.is_signature; }

  @Output('showNoteBoxChange') note_box_emitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input('showNoteBox')
  set toggle_log_note_box(val: boolean) {
    this.show_note_box = val;
  }
  get toggle_log_note_box(): boolean { return this.show_note_box; }

  add_notes_config = {
    case_note: {
      email_url: 'send_case_log_email',
      note_url: 'add_case_notes'
    },
    case_note_email: {
      email_url: 'send_case_log_email',
      note_url: 'add_case_notes'
    }
  }

  constructor(
    private _fb: FormBuilder,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _logDetailsService: AddLogsService
  ) { }

  ngOnInit() {
    this.initialize_log_type();
  }

  open_log_modal() {
    $('#log-modal').modal({ backdrop: 'static', keyboard: false });
  }

  hide_log_modal() {
    this.show_note_box = false;
    this.log_email_cc_value = '';
    this.email_body_empty = false;
    this.email_subject_empty = false;
    $('#log-modal').modal('hide');
  }

  initialize_log_type() {
    for (let i = 0; i < this.log_type.length; i++) {
      if (this.log_type[i] == 'note') {
        this.note_log_type = true;
      } else if (this.log_type[i] == 'email') {
        this.email_log_type = true;
      }
    }
    if (this.email_log_type) {
      this.log_email_value = this.email_value;
    }
  }


  show_log_note_box(val, current_case) {
    this.note_text = '';
    this.email_body = '';
    this.email_subject = '';  
    this.show_note_box = val;
    this.current_log_type = current_case;
    if (current_case == 'email') {
      if (this.user_signature == '') {
        this.is_signature = false;
      } else {
        this.is_signature = true;
      }
    } else {
      this.is_signature = false;
    }
    this.note_box_emitter.emit(this.show_note_box);
    this.open_log_modal();
  }

  add_new_note_event(type) {
    this.log_details_action_event.emit({ 'type': type, 'data': {} });
  }

  add_new_note(_case, id,log_type_case='normal') {
    if (this.note_text == '') {
      this._toastr.error('', 'Please add a note', this._toastrUtility.basic_configuration);
    }
    else {
      this.moduleLoading = true;
      this.document_list = this.uploadDocumentComponent.document_list;
      if (log_type_case !='normal') { 
        this.document_list = [];
      }
      const payload = this.create_notes_payload(id,'Normal',this.document_list);
      this.document_list = [];
      const service = this.add_notes_config[_case].note_url;
      this._logDetailsService.send_data(service, payload).subscribe(res => {
        if (res.errCode == 0) {
          this._toastr.success('', 'New Note Added', this._toastrUtility.basic_configuration);
          this.show_note_box = false;
          this.uploadDocumentComponent.empty_file();
          this.log_details_action_event.emit({ 'type': _case + '_success', 'data': res.data });
          this.hide_log_modal();
        }
        this.moduleLoading = false;
      });
    }
  }

  validate_email(mail) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(mail) == false) {
      return false;
    }
    return true;
  }

  validate_emails_list (email_list) {
    const cc_emails_list = email_list.split(';');
    for (let i =0 ;i < cc_emails_list.length; i++) {
      if (cc_emails_list[i].trim() != '') {
        if(!this.validate_email(cc_emails_list[i].trim())) {
          return cc_emails_list[i];
        }
      }
    }
    return '';
  }

  async send_case_log_email(_case, id, selected_case) {
    console.log(this.email_subject);
    if (this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';') == '') {
      this._toastr.error('', 'Please enter a email', this._toastrUtility.basic_configuration);
      return;
    }
    if ((this.email_subject == '' || this.email_subject == null) && !this.email_subject_empty) {
      this.email_subject_empty = true;
      this._toastr.warning('', 'This email has no subject, Clicking send again will send the email.', this._toastrUtility.basic_configuration);
      return;
    }
    if ((this.email_body == '' || this.email_body == null) && !this.email_body_empty) {
      this.email_body_empty = true;
      this._toastr.warning('', 'This email has no body, Clicking send again will send the email.', this._toastrUtility.basic_configuration);
      return;
    }
    const note_payload = this.create_notes_payload(id,'Email',[]);
    const to_emails_valid = this.validate_emails_list(this.log_email_value);
    if (to_emails_valid != '') {
      this._toastr.error('', to_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return;
    }
    const cc_emails_valid = this.validate_emails_list(this.log_email_cc_value);
    if (cc_emails_valid != '') {
      this._toastr.error('', cc_emails_valid + ' is not valid email address', this._toastrUtility.basic_configuration);
      return;
    }
    const service1 = this.add_notes_config[_case].note_url;
    const service2 = this.add_notes_config[_case].email_url;
    const return_note_data = await this._logDetailsService.send_data(service1,note_payload).toPromise();

    if (return_note_data.errCode == 0) {
      this.document_list = this.uploadDocumentComponent.document_list;
      this.moduleLoading = true;
      const email_payload = {
        'comm_email': this.log_email_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';'),
        'body': this.email_body,
        'subject': selected_case.case_id + ' ' + this.email_subject,
        'biz_org_id': selected_case.biz_org_id,
        'case_id': id,
        'note_id': return_note_data.note_rec.id,
        'document_data_list': this.document_list,
        'send_signature': this.is_signature,
        'cc_list': this.log_email_cc_value.split(';').map((val) => val.trim()).filter(email => email != '').join(';')
      };
      this.document_list = [];
      email_payload['body'] = email_payload['body'].replace(/\n/g, '<br>');
      const wf = await this._logDetailsService.send_data(service2, email_payload).toPromise();

      this.uploadDocumentComponent.empty_file();
      const temp_log_type = _case.replace('_email','');
      if (wf.errCode == 0) {
        this.email_body_empty = false;
        this.email_subject_empty = false;
        this.note_text = `${wf.msg} <br><b>To: </b>${this.log_email_value} <br> <b>CC:</b> ${this.log_email_cc_value}`;
        // this.note_text = `${wf.msg} to ${this.log_email_value}`;
        this.add_new_note(temp_log_type, id,'email');
        this._toastr.success('', `Email sent successfully to ${this.log_email_value}`, this._toastrUtility.basic_configuration);
        this.log_email_cc_value = '';
      } else {
        this.note_text = `Error In Sending Email to ${this.log_email_value}`;
        this.add_new_note(temp_log_type, id, 'email');
      }
      this.moduleLoading = false;
      this.log_details_action_event.emit({ 'type': _case + '_success', 'data': wf });
    }
  }


  create_notes_payload(id, note_type, doc_list) {
    if (note_type == 'Email') {
      this.note_text = this.email_body;
    }
    let payload = {};
    payload = {
      "bobj_table_id": id,
      "note_text": this.note_text,
      'documents_data_list': doc_list,
      'note_type': note_type
    };
    payload['note_text'] = payload['note_text'].replace(/\n/g, '<br>');
    return payload;
  }

  onContentChanged(event) { // quill event
  }

}
