import { Component, OnInit, Input} from '@angular/core';
import { EnvService } from 'src/app/dce-service/env.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb={};
  breadcrumb_map = [];

  @Input() type:string;
  organisation_name = '';
  organisation_name_s = '';

  constructor(
    private _env: EnvService
  ) { }

  ngOnInit() {
    this.organisation_name = this._env.project_variables.org_name;
    this.organisation_name_s = this._env.project_variables.org_name_s;

  	this.breadcrumb = {

	  	'bus_home' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': './',
          'class': true,
          'show' : true
        },

      ],
      'bus_org_details' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': '/busorg/bus-home/div',
          'class': false,
          'show' : true
        },
        {
          'name' : this.organisation_name + ' Details',
          'route': './',
          'class': true,
          'show' : true
        }

      ],
      'div_org_details' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': '/busorg/bus-home/div',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Division Management',
          'route': './',
          'class': true,
          'show' : true
        }

      ],
      'contact_management' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': '/busorg/bus-home/site',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Contact Information',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'site_org_details' : [
      	{
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': '/busorg/bus-home/site',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Location Management',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'employee_management' : [
      	{
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': '/busorg/bus-home/div',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Employee Management',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'emp_full_detail' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'All ' + this.organisation_name_s,
          'route': './',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Organisation Name',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'internal_div_org_details' : [
      	{
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : '',
          'route': './',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Employee Management',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'view_employee_details' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Organisation Name',
          'route': '/internal-org/manage-division',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Employee',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'employee_details' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        }
      ],
      'manage_division' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : '',
          'route': '/internal-org/manage-division',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Department Management',
          'route': './',
          'class': true,
          'show' : true
        }
      ],
      'manage_workgroup' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Manage Workgroups',
          'route': './',
          'class': true,
          'show' : true
        },

      ],
      'workgroup_details' : [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Manage Workgroups',
          'route': '/workgroups/manage-workgroup-users',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Workgroup Details',
          'route': './',
          'class': true,
          'show' : true
        }

      ],
      'create_workgroup'  :  [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Create Workgroup',
          'route': './',
          'class': false,
          'show' : true
        }
      ],
      'manage_task':  [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Create Task',
          'route': './',
          'class': false,
          'show' : true
        }
      ],
      'manage_service':  [
        {
          'name' : 'Home',
          'route': '/dashboard/v1',
          'class': false,
          'show' : true
        },
        {
          'name' : 'Create Service',
          'route': './',
          'class': false,
          'show' : true
        }
      ],
  	}

  	this.breadcrumb_map = this.breadcrumb[this.type];
  }

  update_breadcrumb(index, obj){
    this.breadcrumb[this.type][index] = Object.assign(this.breadcrumb[this.type][index], obj);
  }

}
