export const SchemePopoverTable = [
  {
    scheme: 'Bestmed',
    responsible: 'YiM & MM',
    age: '26 years'
  },
  {
    scheme: 'Bonitas',
    responsible: 'NG & KvR & MK',
    age: '24 years'
  },
  {
    scheme: 'Discovery Health',
    responsible: 'JL & NS & ZT',
    age: '21 years'
  },
  {
    scheme: 'Fedhealth',
    responsible: 'TC & MM & MS',
    age: '26 years'
  },
  {
    scheme: 'Makoti',
    responsible: 'BoM & PM',
    age: '18 years'
  },
  {
    scheme: 'Medihelp',
    responsible: 'TC & JL & IH',
    age: '26 years'
  },
  {
    scheme: 'Medshield',
    responsible: 'MK & YiM',
    age: '24 years'
  },
  {
    scheme: 'Momentum',
    responsible: 'FK & AdG',
    age: '21 years'
  },
  {
    scheme: 'Sizwe',
    responsible: 'RdV & TM & MS',
    age: '24 years'
  },
  {
    scheme: 'Umvuzo',
    responsible: 'PM & KL & YM',
    age: '21 years'
  },
  {
    scheme: 'Universal Healthcare(including CompCare)',
    responsible: 'SM & NG',
    age: '23 years'
  }
]
