import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DceSharedRoutingModule } from './dce-shared-routing.module';
import { TreeComponent } from './tree/tree.component';
import { VisComponent } from './vis/vis.component';
import { AddressComponent } from './address/address.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DatatableWidgetComponent } from './datatable-widget/datatable-widget.component';
import { AddLogsComponent } from './add-logs/add-logs.component';
import { NgCompleterComponent } from './ng-completer/ng-completer.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { AgmCoreModule } from '@agm/core';
import { CodemirrorModule } from 'ng2-codemirror';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NzRoleDirective } from './directives/nz-role.directive';
import { ButtonClickDirective } from '../dce-directive/button-click.directive';
import { AngularDraggableModule } from 'angular2-draggable';
import { TableWidgetComponent } from './table-widget/table-widget.component';
import { CamelCaseWithSplitPipe } from './pipes/camel-case-with-split.pipe';
import { CamelCaseWithoutOrderPipe } from './pipes/camel-case-without-order.pipe';
import { CancelEditComponent } from './cancel-edit/cancel-edit.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DependentsComponent } from './dependents/dependents.component';
import { InfoComponent } from './info/info.component';
import { FilterComponent } from './filter/filter.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { Ng2CompleterModule } from "ng2-completer";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { QuillModule } from 'ngx-quill';

const components = [
  TreeComponent,
  VisComponent,
  AddressComponent,
  BreadcrumbComponent,
  ShowErrorsComponent,
  ContactComponent,
  LoginComponent,
  ChangePasswordComponent,
  DatatableWidgetComponent,
  NzRoleDirective,
  ButtonClickDirective,
  TableWidgetComponent,
  CamelCaseWithSplitPipe,
  CamelCaseWithoutOrderPipe,
  CancelEditComponent,
  DependentsComponent,
  InfoComponent,
  UploadDocumentComponent,
  FilterComponent,
  AddLogsComponent,
  ConfirmationModalComponent,
  NgCompleterComponent,
  SpinnerComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    DceSharedRoutingModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    AngularDraggableModule,
    Ng2CompleterModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule,
    QuillModule,
    AgmCoreModule.forRoot({
      apiKey: ''
    })
  ],
  exports: [
  components,
  CodemirrorModule,
  NzRoleDirective
  ]
})
export class DceSharedModule { }
