import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../dce-service/common.service';
declare var $: any;

@Component({
  selector: 'app-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss']
})
export class TableWidgetComponent implements OnInit {

  @Input() tableCase: string = '';
  @Input() dataList = [];
  @Input() note_case?: string = 'case';

  @Output() modal_data?: EventEmitter<any> = new EventEmitter<any>(); // emit the data of nodes and edges

  constructor(
  	private _common_service: CommonService,
  ) { }

  ngOnInit() {
  }

  fetch_note_docs(index, note_case, note_id){
		var payload = {
			"obj_type": note_case+"_view", 
			"note_id": note_id
		};
		this._common_service.get_workflow_notes_documents(payload).subscribe(res => {
			if(res.errCode == 0){
				this.view_doc_links(res.doc_data, index);
			}
		});
  }

  view_doc_links(doc_data, index) {
    var html_links = '';
    html_links += '<ul style="padding-left: 17px;">';
    for(var i=0; i<doc_data.length; i++){
      html_links += "<li><a target='_blank' href='"+doc_data[i]['document_link']+"'>"+doc_data[i]['document_name']+"</a></li>";
    }
    html_links += '</ul>';
    if (doc_data.length) {
      $('#doc_'+index).html(html_links);
    }else  {
      const no_doc_msg = 'No documents found';
      $('#doc_'+index).html(no_doc_msg);
    }
  }

  show_modal(index) {
    this.modal_data.emit(this.dataList[index]);
  }

}
