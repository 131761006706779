import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FilterJson{
  public filters = {
      'case':[
        {
          'key':'case_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Case ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'emp_national_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Client ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'case_createtime',
          'is_hidden':false,
          'default_val':'',
          'label':'Start Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'case_lastchange_time',
          'is_hidden':false,
          'default_val':'',
          'label':'End Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'prev_owner_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Closed By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'priority',
          'is_hidden':false,
          'default_val':'',
          'label':'Priority',
          'type':'dropdown',
          'data_type':'list',
          'service':null,
          'data':[{'key':'P1','value':'P1'},{'key':'P2','value':'P2'},{'key':'P3','value':'P3'},{'key':'P4','value':'P4'},{'key':'P5','value':'P5'}],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'severity',
          'is_hidden':false,
          'default_val':'',
          'label':'Severity',
          'type':'dropdown',
          'data_type':'list',
          'service':null,
          'data':[{'key':'S1','value':'S1'},{'key':'S2','value':'S2'},{'key':'S3','value':'S3'},{'key':'S4','value':'S4'},{'key':'S5','value':'S5'}],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'prev_wrkgrp_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Workgroup',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_user_workgroups',
          'data':[],
          'data_key':'name',
          'data_id_key':'wrkgrp_id',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'wrkgrp_id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
        {
          'key':'wfobj_status',
          'is_hidden':false,
          'default_val':[],
          'label':'Case Status',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_classification_value_data',
          'data':[],
          'data_key':'value_value',
          'data_id_key':'value_name',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'value_name',
            textField: 'value_value',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        }
      ],
      'task':[
        {
          'key':'task_gen_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Task ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'task_create_time',
          'is_hidden':false,
          'default_val':'',
          'label':'Start Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':true,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'task_lastchange_time',
          'is_hidden':false,
          'default_val':'',
          'label':'End Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':true,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'dropdown_settings':null,
        },
        // {
        //   'key':'prev_owner_full_name',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Closed By',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_users_list',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'full_name',
        //   'dropdown_settings':null,
        // },
        {
          'key':'task_task_type',
          'is_hidden':false,
          'default_val':'',
          'label':'Task Type',
          'type':'dropdown',
          'data_type':'list',
          'service':null,
          'data':[{'key':"Ad-Hoc",'value':"Ad-Hoc"},{'key':"Normal Instance",'value':"Normal Instance"}],
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'task_function_type',
          'is_hidden':false,
          'default_val':'',
          'label':'Task Function Type',
          'type':'task_function',
          'data_type':'list',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'task_function_subtype',
          'is_hidden':false,
          'default_val':'',
          'label':'Task Function Subtype',
          'type':'task_function_sub',
          'data_type':'list',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        // {
        //   'key':'tg_instance_name',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Task Instance Name',
        //   'type':'input',
        //   'data_type':'text',
        //   'service':null,
        //   'data':null,
        //   'mandatory':false,
        //   'data_key':null,
        //   'dropdown_settings':null,
        // },
        {
          'key':'company_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Company Name',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_all_biz_org',
          'data':[],
          'mandatory':false,
          'data_key':'company_name',
          'dropdown_settings':null,
        },
        {
          'key':'prev_wrkgrp_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Workgroup',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_user_workgroups',
          'data':[],
          'data_key':'name',
          'data_id_key':'wrkgrp_id',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'wrkgrp_id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
        {
          'key':'wfobj_status',
          'is_hidden':true,
          'op':'==',
          'default_val':'Closed',
          'label':'Task Status',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':[],
          'data_key':null,
          'data_id_key':null,
          'dropdown_settings':null
        }
      ],
      'advice':[
        {
          'key':'advice_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Advice ID',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'advice_create_time',
          'is_hidden':false,
          'default_val':'',
          'label':'Start Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':true,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'advice_last_change_time',
          'is_hidden':false,
          'default_val':'',
          'label':'End Date',
          'type':'input',
          'data_type':'date',
          'service':null,
          'data':null,
          'mandatory':true,
          'data_key':null,
          'dropdown_settings':null,
        },
        {
          'key':'creator_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created By',
          'type':'autocomplete',
          'data_type':'text',
          'service':'get_users_list',
          'data':[],
          'mandatory':false,
          'data_key':'full_name',
          'dropdown_settings':null,
        },
        {
          'key':'created_for_full_name',
          'is_hidden':false,
          'default_val':'',
          'label':'Created For',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':null,
          'mandatory':false,
          'data_key':null,
          'dropdown_settings':null,
        },        
        // {
        //   'key':'prev_owner_full_name',
        //   'is_hidden':false,
        //   'default_val':'',
        //   'label':'Closed By',
        //   'type':'autocomplete',
        //   'data_type':'text',
        //   'service':'get_users_list',
        //   'data':[],
        //   'mandatory':false,
        //   'data_key':'full_name',
        //   'dropdown_settings':null,
        // },
        {
          'key':'prev_wrkgrp_id',
          'is_hidden':false,
          'default_val':'',
          'label':'Workgroup',
          'type':'multiselect',
          'data_type':'list',
          'service':'get_user_workgroups',
          'data':[],
          'data_key':'name',
          'data_id_key':'wrkgrp_id',
          'dropdown_settings':{
            singleSelection: false,
            idField: 'wrkgrp_id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
          },
        },
        {
          'key':'wfobj_status',
          'is_hidden':true,
          'default_val':'Closed',
          'op':'==',
          'label':'Advice Status',
          'type':'input',
          'data_type':'text',
          'service':null,
          'data':[],
          'data_key':null,
          'data_id_key':null,
          'dropdown_settings':null
        }
      ]
  }
  public operator = {
    'prev_wrkgrp_id':{"op":"in","type":"int"},
    'wfobj_status':{"op":"in","type":"str"},
    'creator_full_name':{"op":"==","type":"str"},
    'prev_owner_full_name':{"op":"==","type":"str"},

    'case_createtime':{"op":">=","type":"str"},
    'case_lastchange_time':{"op":"<=","type":"str"},
    'priority':{"op":"==","type":"str"},
    'severity':{"op":"==","type":"str"},
    'case_id':{"op":"==","type":"str"},
    'emp_national_id':{"op":"==","type":"str"},

    'task_create_time':{"op":">=","type":"str"},
    'task_lastchange_time':{"op":"<=","type":"str"},
    'task_task_type':{"op":"==","type":"str"},
    'task_function_type':{"op":"==","type":"str"},
    'task_function_subtype':{"op":"==","type":"str"},
    'tg_instance_name':{"op":"==","type":"str"},
    'company_name':{"op":"==","type":"str"},
    'task_gen_id':{"op":"==","type":"str"},

    'advice_id':{"op":"==","type":"str"},
    'advice_create_time': { 'op': '>=', 'type': 'str' },
    'advice_last_change_time': { 'op': '<=', 'type': 'str' },
    'created_for_full_name': { 'op': 'ilike_op', 'type': 'str' },
  }
}
