import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { InitService } from './init.service';
import { EnvService } from './env.service';
import { Observable, of } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from './toastr-utility.service';
import { tap, catchError, finalize } from "rxjs/operators";
import { Router } from '@angular/router';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptService implements HttpInterceptor {

	service_count = 0; // counter for service

	constructor(
		private _init: InitService, 
		private _env: EnvService,
		private _toastr: ToastrService,
		private _toastrUtility: ToastrUtilityService,
		private _route:Router,
		private sharedService: SharedService,
	) {}

  	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  		// console.log(req);

		if(1){
			var temp = req.url.split('/');
			var service = "/"+temp.slice(3).join("/");
			// console.log(service);
			// console.log(req.method);

			if (!this._env.skip_spinner_for_services.includes(service)) {
				// console.log(service);
				this.service_count++; // increament count for every http-req service-calls intercepted
				this.sharedService.spinnerVisibility(true);
				// console.log('total service count', this.service_count);
			}

			var token;
			for(var i=0; i<this._env.services_to_skip.length; i++){
				if( this._env.services_to_skip.includes(service) ){	//	skip token addition to service
					token = null;
				} else {
					token = this._init.get_auth_token();
				}
			}

			/*if(req.method == 'GET'){
				if(token){
					var ques = '?';
					if(req.url.split('?').length > 1){
						ques = '';
					}
					const authReq = req.clone({
			      url: 	req.url + 
			      			ques +
				      		'&token=' + token + 
				      		'&dce_profile_role=' + this._init.get_role_from_local() + 
				      		'&dce_global_user_id=' + this._init.get_user_id_from_local() + 
				      		'&dce_global_person_id=' + this._init.get_person_id_from_local()
			    });
			    return next.handle(authReq);
				} else {
					return next.handle(req);
				}

			} else */if (req.method == 'POST'){
				if(token && service.substring(0,4) != '/api' && service.substring(0,6) != '/whapi'){
					// req.body['dce_roles_data'] = this._init.get_local_key('dce_roles_data');

					const authReq = req.clone({
						headers: req.headers.append('Authorization', 'Bearer ' + token), //, 'role': 'role_from_local', 'user_id': 'user_id_from_local'
						body: req.body
					});
					// console.log(req.body);
					// debugger
					
					// return next.handle(authReq);
					return next.handle(authReq).pipe(
						tap((event: HttpEvent<any>) => {
							if (event instanceof HttpResponse && event.body.errCode != undefined && event.body.errCode != 0) {
								this._toastr.error('', event.body.msg, this._toastrUtility.basic_configuration);
								if(event.body.errCode == 96){
								    localStorage.removeItem('token');
									// this._route.navigate(['/sessions/signin'])
									location.reload(true);
								}
							}
						}),
						// catchError((err: any) => {
			   //              if(err instanceof HttpErrorResponse) {
			   //                  try {
			   //                      this._toastr.error('', 'error', this._toastrUtility.basic_configuration);
			   //                  } catch(e) {
			   //                      this._toastr.error('','An error occurred', this._toastrUtility.basic_configuration);
			   //                  }
			   //                  //log error 
			   //              }
			   //              return of(err);
				 //          })
						finalize(() => {
							if (!this._env.skip_spinner_for_services.includes(service)) {
								// console.log(service)
								this.service_count--; // decreament when service is completed (success/failed both handled)
								if (this.service_count === 0) {
									this.sharedService.spinnerVisibility(false);
									// console.log('present service count', this.service_count);
								}
							}
						}),
					);
					
				} else {
					if(token == null && !this._env.services_to_skip.includes(service)){
						this._toastr.error('', "You are not signed in", this._toastrUtility.basic_configuration);
						// this._route.navigate(['/sessions/signin'])
						location.reload(true);
					}	
					return next.handle(req).pipe(
						tap((event: HttpEvent<any>) => {
							if (event instanceof HttpResponse && event.body.errCode != undefined && event.body.errCode != 0) {
								this._toastr.error('', event.body.msg, this._toastrUtility.basic_configuration);
							}
						}),
						finalize(() => {
							if (!this._env.skip_spinner_for_services.includes(service)) {
								// console.log(service)
								this.service_count--; // decreament when service is completed (success/failed both handled)
								if (this.service_count === 0) {
									this.sharedService.spinnerVisibility(false);
									// console.log('present service count', this.service_count);
								}
							}
						}),
					);
								
				}

			} else {
				return next.handle(req).pipe(
					finalize(() => {
						if (!this._env.skip_spinner_for_services.includes(service)) {
							// console.log(service)
							this.service_count--; // decreament when service is completed (success/failed both handled)
							if (this.service_count === 0) {
								this.sharedService.spinnerVisibility(false);
								// console.log('present service count', this.service_count);
							}
						}
					}),
				);
			}

			// debugger

		} else {
			return next.handle(req);
		}
		// send cloned request with header to the next handler.
	}
 
  	// intercept(res: HttpResponse<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  		// console.log(res);
  		// debugger



		// if(1){
		// 	var temp = req.url.split('/');
		// 	var service = "/"+temp.slice(3).join("/");
		// 	// console.log(service);
		// 	// console.log(req.method);

		// 	var token;
		// 	for(var i=0; i<this._env.services_to_skip.length; i++){
		// 		if( this._env.services_to_skip.includes(service) ){	//	skip token addition to service
		// 			token = null;
		// 		} else {
		// 			token = this._init.get_auth_token();
		// 		}
		// 	}

		// 	if(req.method == 'GET'){
		// 		if(token){
		// 			var ques = '?';
		// 			if(req.url.split('?').length > 1){
		// 				ques = '';
		// 			}
		// 			const authReq = req.clone({
		// 	      url: 	req.url + 
		// 	      			ques +
		// 		      		'&token=' + token + 
		// 		      		'&dce_profile_role=' + this._init.get_role_from_local() + 
		// 		      		'&dce_global_user_id=' + this._init.get_user_id_from_local() + 
		// 		      		'&dce_global_person_id=' + this._init.get_person_id_from_local()
		// 	    });
		// 	    return next.handle(authReq);
		// 		} else {
		// 			return next.handle(req);
		// 		}

		// 	} else if (req.method == 'POST'){
		// 		if(token && service.substring(0,4) != '/api' && service.substring(0,6) != '/whapi'){
		// 			// req.body['dce_roles_data'] = this._init.get_local_key('dce_roles_data');

		// 			const authReq = req.clone({
		// 				headers: req.headers.append('Authorization', 'Bearer ' + token), //, 'role': 'role_from_local', 'user_id': 'user_id_from_local'
		// 				body: req.body
		// 			});
		// 			// console.log(req.body);
		// 			// debugger
		// 			return next.handle(authReq);
		// 		} else {
		// 			return next.handle(req);
		// 		}

		// 	} else {
		// 		return next.handle(req);	
		// 	}

		// 	// debugger

		// } else {
		// 	return next.handle(req);
		// }
		// send cloned request with header to the next handler.

	// }
 

}
