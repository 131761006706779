import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgCompleterService } from './ng-completer.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-ng-completer',
  templateUrl: './ng-completer.component.html',
  styleUrls: ['./ng-completer.component.scss']
})
export class NgCompleterComponent implements OnInit {

  @Input() searchKeyword: string = '';
  @Input() inputValue: string = '';
  @Input() placeHolder?: string = 'Search';
  @Input() isDisable?: boolean = false;
  @Input() clearIfWrongInput?:boolean = false;
  @Input() dynamicData?: boolean = false;
  @Input() serviceCallData?: any;
  @Input() searchKey?:string = 'query';
  @Input() url?: string = '';

  @Input() search_case?:string = 'default';
  @Output() outputValue: EventEmitter<any> = new EventEmitter<any>();

  private subject: Subject<string> = new Subject();  

  data:any = [];
  @Input()
  set dataList(val: any) {
    this.data = val;
  }
  get dataList(): any { return this.data; }

  constructor(
    public _completerService: NgCompleterService
  ) { }


  ngOnInit() {
    this.subject.pipe(
      debounceTime(200)).subscribe((title) => {
        const payload = this.serviceCallData;
        payload[this.searchKey] = title;
        if (this.search_case =='default') {
          if (payload['biz_org_id'] != null) {
            this.completer_service_call(payload);
          }
        } else if (this.search_case == 'employer') {
          this.completer_service_call(payload);
        }
      }
    );
  }

  completer_service_call(payload) {
    this._completerService.get_searched_data(this.url,payload).subscribe(res => {
      if (res.errCode == 0) {
        this.dataList = [...res.msg];
        this.onChanged({});
      }
    });
  }

  completer_select(event) {
    this.outputValue.emit(event);
  }

  completer_closed () {
    if (this.clearIfWrongInput) {
      const data = this.autocomplete_value_exist(this.dataList,this.searchKeyword,this.inputValue) 
      if (!data.status) {
        this.completer_select(data.value); 
        this.inputValue = '';
      }
    }
  }

  completer_input_changed($event) {
    if (this.dynamicData) {
      this.completer_advanced_search($event);
    }else {
      this.onChanged($event);
    }
  }

  autocomplete_value_exist(data_list,search_keyword, formNameValue) {
    if (typeof(formNameValue) == 'object'){
      for (let i =0 ;i< data_list.length;i++) {
        if (data_list[i][search_keyword] == formNameValue[search_keyword]) {
          return {status: true,value: formNameValue};
        }
      }
    } else if (typeof(formNameValue) == 'string') {
      for (let i = 0 ;i < data_list.length;i++) {
        if (data_list[i][search_keyword] == formNameValue) {
          return {status: true,value: formNameValue};
        }
      }
    }
    return {status: false,value: null};
  }

  onChanged($event){
    setTimeout(function(){
      if(!$('.autocomplete-container').hasClass('active')){
        $('.suggestions-container').hide()
        $('.not-found').hide()
      }else{
        $('.suggestions-container').show()
        $('.not-found').show()
      }
    },10);
  }

  completer_advanced_search(value) {
    let title = value.trim();
    if (title.toString().length > 1) {
      this.subject.next(title);
    } else if (title.toString().length <= 2) {
      this.dataList = [];
    }
  }

  onClearInput() {
    this.outputValue.emit(null);
  }

}
