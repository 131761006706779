import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EnvService } from '../../dce-service/env.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
	document_list= [];
	@Input() doc_case = "";
	@Input() max_file_count = 5;
	@Input() max_file_size = 1048576*5;
	@Input() doc_identifier = "";
	@Input() upload_input_id="";
	@Output() upload_limit_message: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  	private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private _env: EnvService,
	) { }

  ngOnInit() {

  }

  //multiple file upload
  multiple_upload_file(fileInput){
		var self = this;
    self.document_list = [];
    if (fileInput.target.files && fileInput.target.files[0]) {
      if(fileInput.target.files.length > this.max_file_count){
        this.empty_file()
        this._toastr.warning('','Cannot select more than ' + this.max_file_count +' five files', this._toastrUtility.basic_configuration);
        self.document_list = [];
      }
      else{
        var file_names = [];
        var files_data = [];
        for(let i=0; i<fileInput.target.files.length; i++){
          if(this.checkFile(fileInput.target.files[i])){
            if(fileInput.target.files[i].size > this.max_file_size){
              this.empty_file();
              this._toastr.warning('','File is too big', this._toastrUtility.basic_configuration);
              self.document_list = [];
              return;
            }
            else{
              file_names.push(fileInput.target.files[i]['name']);        
            }
          }
        }
        this.readFile(0, fileInput.target.files, file_names);
      }

    } else {
      self.document_list = [];
    }
  }

  readFile(index, files, file_names) {
    var self = this;
    if( index >= files.length ) return;
    var file = files[index];
    var reader = new FileReader(); 
    reader.onload = function(e: any) {  
      // get file content  
      var bin = e.target.result;
      console.log();
      // do sth with bin
      self.document_list.push({
        "document_name": file_names[index],
        "document_data": bin
      })
      self.readFile(index+1, files, file_names);
    }
    reader.readAsDataURL(file);
    return self.document_list;
  }


  //single file upload
  single_upload_file(fileInput: any){
		var self = this;
    self.document_list = [];
    if (fileInput.target.files[0]) {
      if(this.checkFile(fileInput.target.files[0])){
      	if(fileInput.target.files[0].size > this.max_file_size){
          this.empty_file();
          this._toastr.warning('','File is too big', this._toastrUtility.basic_configuration);
          self.document_list = [];
        }
        else{ 	
  		    var file_name = '';
  		    file_name = fileInput.target.files[0]['name'];
  		    var self = this;
  		    this.validate_identifier(this.doc_identifier);

  		    var reader = new FileReader();
  		    reader.onload = function (e: any) {
  		      var bin = e.target.result;
  		      self.document_list.push({
  		        "document_name": file_name,
  		        "document_data": bin,
  		        "document_identifier": self.doc_identifier
  		      })
  		    }
  		    reader.readAsDataURL(fileInput.target.files[0]);
  		  	return this.document_list;
        }
      }
    }
    else {
      self.document_list = [];
    }
  }

  validate_identifier(indentifier) {
    // console.log(this.document_list.length)
    for (let i = 0; i < this.document_list.length; i++) {
      if (this.document_list[i]['document_identifier'] == indentifier) {
        this.document_list.splice(i, 1);
      }
    }
  }

  empty_file(){
	  var input = $("#"+this.upload_input_id);
    input.val('');
  }

  //check file format .exe,.sh
  checkFile(fileInput){
    // console.log(fileInput)
    var fileExtension = "";
      if (fileInput.name.lastIndexOf(".") > 0) {
          fileExtension = fileInput.name.substring(fileInput.name.lastIndexOf(".") + 1, fileInput.name.length);
          console.log(this._env.blacklist_file.length);
      }

      if ($.inArray(fileExtension.toLowerCase(), this._env.blacklist_file) === -1) {
        return true;
      }
      else {
        this.document_list = [];
        this._toastr.warning('','Cannot select files with' + ' ' + this._env.blacklist_file + ' ' +'extension', this._toastrUtility.basic_configuration);
        this.empty_file();
        return false;
      }
  }

}
