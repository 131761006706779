import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { CommonService } from './common.service';
import { EnvService } from './env.service';
import { web_page_title } from './env.service';
import { ProjectSharedService } from './project-shared.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SharedService extends ProjectSharedService{

  isLoading = new BehaviorSubject(false);
  roles_map = {};

  values_data: any = {};

  constructor(
    private _commonService: CommonService,
    private _env: EnvService,
    private _router: Router,
  ) {
    super();
    document.title = web_page_title;
    

  }

  async get_data_after_refresh() {
    const wf = await this._commonService.get_data_after_refresh({}).toPromise();
    this.setValue('internal_biz_id', wf.internal_biz_id);
    this.setValue('user_details', wf.user_details);
    this.setValue('internal_biz_name', wf.internal_biz_name);
    this.setValue('user_id', wf.user_details.user_id);
    this.setValue('current_role', wf.current_role);
    this.setValue('user_roles', wf.user_roles);
  }

  setValue(new_key: string, value: any): void  {
    this.values_data[new_key] = value;
  }

  getValue(key_name: string) {
    return this.values_data[key_name];
  }


  // method to show/hide spinner
  spinnerVisibility(is_visible) {
   this.isLoading.next(is_visible);
  }


  // ROLES CODE
  async promise_fn() {
    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != null) {
      var res = await this._commonService.get_user_role_map({}).toPromise();
      this.roles_map = res.msg.component_data;
      this.get_data_after_refresh();
      // this.get_user_role_map();
      return res;
    } else {
      this._router.navigate(['./sessions/signin']);
    }
  }

  shared_services_before_routing(): Observable<any> {
    var res = this.promise_fn();

    // forced 1 second delay on refresh
    return of(res).pipe(delay(1000));

  }

  get_roles_map(){
    return this.roles_map;
  }

  check_dt_role_access(key) {
    var param = key.split("__");

    if(this.roles_map[param[0]] != undefined && this.roles_map[param[0]][param[1]] != undefined){
      if(this.roles_map[param[0]][param[1]] == 1){
        return true;
      } else {
        return false;
      }
    } else {
      return true;    //  should be false.. commiting for demo purpose.
    }
  }

}
