import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-edit',
  templateUrl: './cancel-edit.component.html',
  styleUrls: ['./cancel-edit.component.scss']
})
export class CancelEditComponent implements OnInit {

	@Input() payload_dict:any = {};
	@Output() edit_event: EventEmitter<any> = new EventEmitter<any>();
	previous_value :any = {};
	@Input() show_edit_button:boolean;
	@Input() class_="";
	constructor() { }

	ngOnInit() {
	}
	click_event(case_){
		let data = {
			'case': case_,
			'payload': {},
			'editing': this.show_edit_button
		}
		switch (case_) {
			case "edit":
				this.show_edit_button = true
				for (var i in this.payload_dict) {
					if(this.payload_dict[i]){
						if(this.payload_dict[i].constructor.name == "FormGroup"){
							this.previous_value[i] = JSON.parse(JSON.stringify(this.payload_dict[i].getRawValue())); 
						}else{
							this.previous_value[i] = JSON.parse(JSON.stringify(this.payload_dict[i]))
						}
					}else{
						this.previous_value[i] = this.payload_dict[i]
					}
				}
				break;
		case "cancel-edit":
				this.show_edit_button = false
				data['payload'] = this.previous_value
			break;
		case "update":
			this.show_edit_button = false	
			break;
		}
	this.edit_event.emit(data);

	}
}
