import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { EnvService } from 'src/app/dce-service/env.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import {  ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { CustomValidators } from 'src/app/dce-service/custom-validators.component';
import { format } from 'date-fns';
import { faPlus, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { SchemePopoverTable } from './dependents.config';
declare var $: any;

@Component({
  selector: 'app-dependents',
  templateUrl: './dependents.component.html',
  styleUrls: ['./dependents.component.scss']
})
export class DependentsComponent implements OnInit {

  @Input() dependent_type = '';
  dependents: FormGroup;
  current_date = format(new Date(), 'YYYY-MM-DD');
  @Output() dependent_length?: EventEmitter<any> = new EventEmitter();

  submitted_dependents = false;
  adult_dependent_list = [];
  child_dependent_list = [];
  scheme_exemption_list = [];

  constructor(
    private _fb: FormBuilder,
    private _env: EnvService,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService,
    private library: FaIconLibrary,
  ) {
    library.addIcons(faPlus, faTimes, faSearch);
  }

  get formArray() { return <FormArray>this.dependents.get('dependent_details'); }

  ngOnInit() {  
    this.scheme_exemption_list = SchemePopoverTable;
    this.get_classification_data();
    this.initialize_payload();
  }

  display_exemption_table() {
    $('[data-toggle=popover]').popover();
    return $('#exemptionPopoverContent').html()
  }

  isNumber(value) {
    return Number.isNaN(value);
  }
  get_classification_data() {
    var dependent_id = this._env.project_dropdown_list.adult_dependent;
    if (this.dependent_type == 'child') {
      dependent_id = this._env.project_dropdown_list.child_dependent;
    }
    var payload = {
      'classification_type_id': [dependent_id],
      'classification_value_id': [-1]
    };
    console.log(payload)
    this._commonService.get_particular_classification_data(payload).subscribe(res => {
      if (res.errCode == 0) {
        console.log(res);
        for (let i = 0; i < res.msg.length; i++) {
          if (res.msg[i].classification_type == this._env.project_dropdown_list.adult_dependent) {
            this.adult_dependent_list = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.project_dropdown_list.child_dependent) {
            this.child_dependent_list = res.msg[i].data;
          } else {
            // do_nothing
          }
        }
      }
    });
  }

  initialize_payload() {
    this.dependents = this._fb.group({
      dependent_details: this._fb.array([])
    });
  }

  add_new_dependent_details() {
    const control = <FormArray>this.dependents.controls['dependent_details'];
    control.push(
      this._fb.group({
        'id': this._fb.control(-1),
        'first_name': this._fb.control('', Validators.required),
        'middle_name': this._fb.control(''),
        'last_name':  this._fb.control('', Validators.required),
        'dep_relation':  this._fb.control('', Validators.required),
        'dob':  this._fb.control('', [Validators.required]),
        'age':  this._fb.control(''),
        'is_exemption': this._fb.control(false)
      })
    );
    if (this.dependent_type == 'child') {
      this.validate_is_exemption(this.get_length_of_dependents()-1, false);
    } else {
      this.formArray.at(this.get_length_of_dependents()-1).get('dob').setValidators([Validators.required, CustomValidators.adultAge]);
    }
  }

  clear_form() {
    this.formArray.clear();
  }

  validate_dependent_details() {
    this.submitted_dependents = true;
    if (this.formArray.status == 'VALID' || this.formArray.status == 'DISABLED') {
      return true;
    } else {
      return false;
    }
  }

  validate_is_exemption(index, event) {
    this.formArray.at(index).get('dob').setValidators([]);
    if(event == true){
      this.formArray.at(index).get('dob').setValidators([Validators.required, CustomValidators.isExemptionDependentDate]);
    }else if(event == false){
      this.formArray.at(index).get('dob').setValidators([Validators.required, CustomValidators.isNotExemptionDependentDate]);
    }
    this.formArray.at(index).get('dob').updateValueAndValidity();
  }

  // fill_contacts(index, contact_type) {
  //   this.formArray.at(index).patchValue({
  //     'id': -1,
  //     'contact_type': contact_type,
  //     'contact_subtype':  'Official',
  //     'contact_data': ''
  //   });
  //   // this.update_validator(index)
  //   this.formArray.at(index).get('contact_type').disable();
  //   this.formArray.at(index).get('contact_subtype').disable();
  //   this.disable_index.push(index);
  // }

  remove_dependent_details(index) {
    this.formArray.removeAt(index);
    const dep_length = this.get_length_of_dependents();
    this.dependent_length.emit(dep_length);
  }

  fill_dependent_details(data_list) {
    for (let i = 0; i < data_list.length; i++ ) {
      if (this.formArray.length < data_list.length) {
        this.add_new_dependent_details();
      }
      this.formArray.at(i).patchValue({
        'id': data_list[i].dep_id,
        'first_name': data_list[i].dep_first_name,
        'middle_name': data_list[i].dep_middle_name,
        'last_name': data_list[i].dep_last_name,
        'dep_relation': data_list[i].dep_dep_relation,
        'dob': data_list[i].dep_dob,
        'age': data_list[i].dep_age
      });
    }
  } 

  get_length_of_dependents() {
    const length = this.formArray.length
    return length;
  }

  get_age_from_dob() {
    for (let i = 0; i < this.formArray.length; i++) {
      const dob_value = this.formArray.at(i).value.dob;
      const birthday = new Date(dob_value);
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs);
      const age_value = Math.abs(ageDate.getUTCFullYear() - 1970);
      this.formArray.at(i).patchValue({
        'age': age_value
      })
    }
  }

  // validate_phone_email() {
  //   if (this.formArray.length >= 2) {
  //     let is_phone_number = false;
  //     let is_email = false;
  //     // Below is Future Scope
  //     // const validate_columns_array = [];
  //     // this.formArray.value.map(con => validate_columns_array.push(con.contact_type));
  //     // console.log(validate_columns_array);

  //     for (let i = 0 ; i < this.formArray.length; i++) {
  //       if (this.formArray.getRawValue()[i].contact_type == 'Phone Number' && this.formArray.getRawValue()[i].contact_subtype == 'Official') {
  //         is_phone_number = true;
  //       } else if (this.formArray.getRawValue()[i].contact_type == 'Email' && this.formArray.getRawValue()[i].contact_subtype == 'Official') {
  //         is_email = true;
  //       }
  //     }
  //     if (is_phone_number && is_email) {
  //       return true;
  //     }
  //   } else {
  //     return false;
  //   }
  // }
  // update_validator(index){
  //   if(this.formArray.at(index).get('contact_type').value == "Phone Number"){
  //     this.formArray.at(index).get('contact_data').setValidators([Validators.required,CustomValidators.mobileNo_10])
  //   }else if(this.formArray.at(index).get('contact_type').value == "Email"){
  //     this.formArray.at(index).get('contact_data').setValidators([Validators.required,Validators.email])
  //   }else{
  //     this.formArray.at(index).get('contact_data').setValidators([]);
  //   }
  //   this.formArray.at(index).get('contact_data').updateValueAndValidity();
  // }
  
}
