import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../dce-service/env.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient,
    private env: EnvService) { }


  private address_search_url = `${this.env.service_url}/search_location`;
  address_search(case_type, data) {

    const api_map = {
      'locality1': 'locality',
      'locality2': 'suburb',
      'city': 'city',
      'metro_municipality': 'district',
      'province': 'province'
    };

    const payload = { 'search_query': data, 'type': api_map[case_type] };
    return this.http.post<any>(this.address_search_url, payload);
  }

  private match_and_populate_address_url = `${this.env.service_url}/match_and_populate_address`;
  match_and_populate_address(payload) {
    return this.http.post<any>(this.match_and_populate_address_url, payload);
  }


}
