<div class="main-header">
    <div class="logo">
        <a routerLink="/dashboard/v1"><img src="./assets/images/logo.png" alt="" style="cursor: pointer"></a>
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <!-- Mega menu -->
    <!-- <div class="d-flex align-items-center">
    
        <div ngbDropdown class="mega-menu d-none d-md-block">
            <button ngbDropdownToggle href="#" class="btn text-muted dropdown-toggle mr-3">Mega Menu</button>
            <div ngbDropdownMenu perfectScrollbar>
                <div class="row m-0">
                    <div class="col-md-4 p-4 bg-img">
                        <h2 class="title">Mega Menu <br> Sidebar</h2>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores natus
                            laboriosam fugit, consequatur.
                        </p>
                        <p class="mb-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                            Exercitationem odio amet eos dolore suscipit placeat.</p>
                        <button class="btn btn-lg btn-rounded btn-outline-warning">Learn More</button>
                    </div>
                    <div class="col-md-4 p-4">
                        <p class="text-primary text--cap border-bottom-primary d-inline-block">Features</p>
                        <div class="menu-icon-grid w-auto p-0">
                            <a href="#"><i class="i-Shop-4"></i> Home</a>
                            <a href="#"><i class="i-Library"></i> UI Kits</a>
                            <a href="#"><i class="i-Drop"></i> Apps</a>
                            <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                            <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                            <a href="#"><i class="i-Ambulance"></i> Support</a>
                        </div>
                    </div>
                    <div class="col-md-4 p-4">
                        <p class="text-primary text--cap border-bottom-primary d-inline-block">Components</p>
                        <ul class="links">
                            <li><a href="accordion.html">Accordion</a></li>
                            <li><a href="alerts.html">Alerts</a></li>
                            <li><a href="buttons.html">Buttons</a></li>
                            <li><a href="badges.html">Badges</a></li>
                            <li><a href="carousel.html">Carousels</a></li>
                            <li><a href="lists.html">Lists</a></li>
                            <li><a href="popover.html">Popover</a></li>
                            <li><a href="tables.html">Tables</a></li>
                            <li><a href="datatables.html">Datatables</a></li>
                            <li><a href="modals.html">Modals</a></li>
                            <li><a href="nouislider.html">Sliders</a></li>
                            <li><a href="tabs.html">Tabs</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="search-bar">
            <input type="text" placeholder="Search" (focus)="searchService.searchOpen = true">
            <i class="search-icon text-muted i-Magnifi-Glass1"></i>
        </div>
    </div> -->

    <div style="margin: auto"></div>

    <div class="header-part-right no-wrap">
        <!-- <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i> -->
        <fa-icon icon="exchange-alt" class="header-icon d-none d-sm-inline-block" style="cursor: pointer;" aria-hidden="true" data-toggle="modal" data-target="#switchProfileModal"></fa-icon>
        <!-- <li style="display: inline;" [hidden]="profiles.length == 1"><a style="cursor:pointer; color:white;" data-toggle="modal" data-target="#defaultmodal"><i class="fas fa-exchange-alt"></i> Switch Profile</a></li> -->
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
        <!-- <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
            <i ngbDropdownToggle class="i-Gear text-muted header-icon" role="button"></i>
            <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                <div class="menu-icon-grid">
                    <a href="#"><i class="i-Shop-4"></i> Home</a>
                    <a href="#"><i class="i-Library"></i> UI Kits</a>
                    <a href="#"><i class="i-Drop"></i> Apps</a>
                    <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                    <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                    <a href="#"><i class="i-Ambulance"></i> Support</a>
                </div>
            </div>
        </div> -->

        <div ngbDropdown [placement]="'bottom-right'">
            <div class="badge-top-container">
                
                <span *ngIf="notifications.length!=0" class="badge badge-primary">{{unread_notification}}</span>
                <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>

            </div>
            <div ngbDropdownMenu  *ngIf="notifications.length != 0" class="notification-dropdown rtl-ps-none" perfectScrollbar>
                <div class="dropdown-item-custom d-flex" *ngFor="let item of recent_notifications
                ">  
                <!-- (click)="mark_as_read_notfication(item.id)" -->
                    <!-- <div class="notification-icon">
                        <i class="{{item.icon}} text-{{item.status}} mr-1"></i>
                    </div> -->
                    <div class="notification-details flex-grow-1 p-1" [ngClass]="{read_notifiaction: item.status != 'Unread'}">
                        <p class="m-0 pt-2 word-break">
                            <span>{{item.subject}}</span><br>
                            <!-- <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ml-1 mr-1">{{item.badge}}</span> -->
                        </p>
                        
                        <p class="text-small text-muted m-0 word-break" style="max-width: 400px" [innerHTML]="item.message"></p>
                        <p class="m-0 mt-1">
                            <span class="text-small text-muted"><u>{{item.create_datetime | relativeTime}}</u></span>
                            <button class="btn btn-sm btn-primary float-right" *ngIf="item.status=='Unread'" (click)="mark_as_read_notfication(item.id,false)">Mark As Read</button>
                        </p>
                    </div>
                </div>
                <div class="my-1 p-1 " style="text-align: center" *ngIf="unread_notification>0">
                    <button class="btn btn-sm btn-primary "  style="cursor: pointer" (click)="mark_as_read_notfication(-1,true)">Mark All Read</button>
                </div>
                <div class="my-1 p-1 " style="text-align: center"  *ngIf="notifications.length>10">
                    <a class="btn btn-sm btn-primary" routerLink="/notifications/all_notifications">
                        Show All Notifications
                    </a>
                </div>
            </div>
            <div ngbDropdownMenu  *ngIf="notifications.length == 0" class="notification-dropdown rtl-ps-none p-3" perfectScrollbar>
                No Notifications
            </div>
        </div>
        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <span>{{user_full_name}}</span>
            <img src="./assets/images/faces/caret-down.jpg" id="userDropdown" ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                    <i class="i-Lock-User mr-1"></i> {{user_email}}
                </div>
                <!-- <button class="dropdown-item">Account settings</button>
                <button class="dropdown-item">Billing history</button> -->
                <button class="dropdown-item" routerLink="/user-profile/profile">User Profile</button>
                <button class="dropdown-item" routerLink="/user-profile/reset-password/0">Change Password</button>
                <button class="dropdown-item" (click)="signout()">Sign out</button>
            </div>
        </div>

    </div>

</div>

<!-- Sidebar Switch Profile Modal-->
<div class="modal fade" id="switchProfileModal">
    <div class="modal-dialog">
       <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col text-center my-5">
                        <fa-icon class="fa-4x" icon="exchange-alt" ></fa-icon>
                        <h3 class="my-2">Switching to another profile?</h3>
                        <p class="mt-4">Currently logged in as:</p>
                        <h4 class="hlc-heavy">{{current_profile.role_name }}</h4>
                        <p class="mt-4 mb-5">Select a profile to switch to:</p>
                        <div class="row">
                            <div *ngFor="let pro of profiles; let i=index;" class="col text-center user-pointer" >
                                <span *ngIf="pro.role_id == current_profile.role_id">
                                    <div><b><fa-icon class="fa-2x" icon="user" ></fa-icon> <br>
                                        {{pro.role_name }}</b>
                                    </div>
                                </span>
                                <span *ngIf="pro.role_id != current_profile.role_id">
                                    <div data-dismiss="modal" (click)="switch_role(pro.role_id)" style="cursor: pointer;">   <fa-icon class="fa-2x" icon="user" ></fa-icon> <br>
                                        {{pro.role_name }}
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"> Close</button>
            </div>
        </div>
    </div>
</div>