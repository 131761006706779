import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../dce-service/env.service';

@Injectable({
  providedIn: 'root'
})
export class TreeService {

  constructor(	private http: HttpClient, 
  				private env: EnvService) { }

  	private _div_tree_data = `${this.env.service_url}/`;

	get_tree_data(service, id, menu_items) {
		const data = { "id" : id , "menu_items": menu_items}

		return this.http.post<any>(this._div_tree_data + service, data)
	}

}
