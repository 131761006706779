import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseWithSplit'
})
export class CamelCaseWithSplitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let ret_val;
    ret_val = value.split('_');
    ret_val = ret_val.map(x => x[0].toUpperCase() + x.substring(1));
    ret_val = ret_val.join(' ');

    return ret_val;
  }

}
