import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { EnvService } from 'src/app/dce-service/env.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import {  ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { CustomValidators } from 'src/app/dce-service/custom-validators.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contact_details: FormGroup;
  submitted_contact_details = false;

  communication_type = [];
  communication_subtype = [];
  is_disable = false;
  disable_index = [];

  constructor(
    private _fb: FormBuilder,
    private _env: EnvService,
    private _commonService: CommonService,
    private _toastr: ToastrService,
    private _toastrUtility: ToastrUtilityService
  ) { }

  get formArray() { return <FormArray>this.contact_details.get('contact'); }

  ngOnInit() {
    this.get_classification_data();
    this.initialize_payload();
  }

  get_classification_data() {
    const payload = {
      'classification_type_id': [this._env.dropdown_list.communication_type, this._env.dropdown_list.communication_subtype] ,
      'classification_value_id': [-1, -1]
    };
    this._commonService.get_particular_classification_data(payload).subscribe(res => {
      if (res.errCode == 0) {
        for (let i = 0; i < res.msg.length; i++) {
          if (res.msg[i].classification_type == this._env.dropdown_list.communication_type) {
            this.communication_type = res.msg[i].data;
          } else if (res.msg[i].classification_type == this._env.dropdown_list.communication_subtype) {
            this.communication_subtype = res.msg[i].data;
          }
        }
      }
    });
  }

  initialize_payload() {
    this.contact_details = this._fb.group({
      contact: this._fb.array([])
    });
    this.add_contact();
    this.fill_contacts(0, 'Phone Number');
    this.add_contact();
    this.fill_contacts(1, 'Email');
  }

  add_contact() {
    const control = <FormArray>this.contact_details.controls['contact'];
    control.push(
      this._fb.group({
        'id': this._fb.control(-1),
        'contact_type': this._fb.control('', Validators.required),
        'contact_subtype':  this._fb.control('', Validators.required),
        'contact_data':  this._fb.control('', Validators.required)
      })
    );
    // this.submitted_contact_details = false
  }

  clear_form() {
    this.formArray.clear();
  }

  validate_contact_details() {
    this.submitted_contact_details = true
    if (this.formArray.status == 'VALID' || this.formArray.status == 'DISABLED') {
      return true;
    } else {
      return false;
    }
  }

  fill_contacts(index, contact_type) {
    this.formArray.at(index).patchValue({
      'id': -1,
      'contact_type': contact_type,
      'contact_subtype':  'Official',
      'contact_data': ''
    });
    this.update_validator(index)
    this.formArray.at(index).get('contact_type').disable();
    this.formArray.at(index).get('contact_subtype').disable();
    this.disable_index.push(index);
  }

  remove_contact_details(index) {
    this.formArray.removeAt(index);
    if(index < this.disable_index[0]) {
      for (let i =0 ;i < this.disable_index.length; i++) {
        this.disable_index[i]--;
      }
    }
  }

  fill_contact_payload(contact_list) {
    let official_phone_number_count = 0;
    let official_email_count = 0;
    this.disable_index = [];
    for (let i = 0; i < contact_list.length; i++ ) {
      if (this.formArray.length < contact_list.length) {
        this.add_contact();
      }

      this.formArray.at(i).patchValue({
        'id': contact_list[i].id,
        'contact_type': contact_list[i].contact_type,
        'contact_subtype':  contact_list[i].contact_subtype,
        'contact_data':  contact_list[i].contact_data
      });
      this.update_validator(i)
      if (official_email_count < 1 || official_phone_number_count < 1) {
        if (this.formArray.at(i).get('contact_subtype').value == 'Official' &&
        this.formArray.at(i).get('contact_type').value == 'Email') {
          official_email_count++;
          this.formArray.at(i).get('contact_type').disable();
          this.formArray.at(i).get('contact_subtype').disable();
          this.disable_index.push(i);
        } else if (this.formArray.at(i).get('contact_subtype').value == 'Official' &&
          this.formArray.at(i).get('contact_type').value == 'Phone Number') {
          official_phone_number_count++;
          this.formArray.at(i).get('contact_type').disable();
          this.formArray.at(i).get('contact_subtype').disable();
          this.disable_index.push(i);
        } else {
          this.formArray.at(i).get('contact_type').enable();
          this.formArray.at(i).get('contact_subtype').enable();
        } 
      }

    }
    if (contact_list.length== 0) {
      this.disable_index.push(0);
      this.disable_index.push(1);
    } else if (contact_list.length== 1) {
      this.disable_index.push(1);
    }
  } 

  validate_phone_email() {
    if (this.formArray.length >= 2) {
      let is_phone_number = false;
      let is_email = false;
      // Below is Future Scope
      // const validate_columns_array = [];
      // this.formArray.value.map(con => validate_columns_array.push(con.contact_type));
      // console.log(validate_columns_array);

      for (let i = 0 ; i < this.formArray.length; i++) {
        if (this.formArray.getRawValue()[i].contact_type == 'Phone Number' && this.formArray.getRawValue()[i].contact_subtype == 'Official') {
          is_phone_number = true;
        } else if (this.formArray.getRawValue()[i].contact_type == 'Email' && this.formArray.getRawValue()[i].contact_subtype == 'Official') {
          is_email = true;
        }
      }
      if (is_phone_number && is_email) {
        return true;
      }
    } else {
      return false;
    }
  }
  update_validator(index){
    if(this.formArray.at(index).get('contact_type').value == "Phone Number"){
      this.formArray.at(index).get('contact_data').setValidators([Validators.required,CustomValidators.mobileNo_10])
    }else if(this.formArray.at(index).get('contact_type').value == "Email"){
      this.formArray.at(index).get('contact_data').setValidators([Validators.required,Validators.email])
    }else{
      this.formArray.at(index).get('contact_data').setValidators([]);
    }
    this.formArray.at(index).get('contact_data').updateValueAndValidity();
  }
  
}
