import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from '../../dce-service/env.service';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = true;
  private login_url = `${this.env.service_url}/login`;
  private logout_url = `${this.env.service_url}/logout`;
  private admin_login_url = `${this.env.service_url}/?`;


  constructor(private store: LocalStoreService, private router: Router, private env: EnvService,
    private http: HttpClient) {
    this.checkAuth();
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return of({}).pipe(delay(1500));
  }
  signout() {
    this.authenticated = false;
    localStorage.removeItem('token')
    this.store.setItem("demo_login_status", false);
    this.router.navigateByUrl("/sessions/signin");
  }

  login_service(payload) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return this.http.post<any>(this.login_url, payload);

  }

  logout(payload) {
    return this.http.post<any>(this.logout_url, payload);
  }

  admin_login_service(payload) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return this.http.post<any>(this.admin_login_url, payload);
  } 

}
