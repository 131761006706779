import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EnvService } from './env.service';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { Observable, observable } from "rxjs";
import { ProjectService } from './project.service';
import { CommonObject } from './common-object';

declare var $: any;

@Injectable({
	providedIn: 'root'
})
export class CommonService extends ProjectService {
	lambda = {
		'equal': function(op1,op2){ return op1 == op2} ,
		'not_equal': function(op1,op2){ return op1 != op2} ,
		'less': function(op1,op2){ return op1 < op2} ,
		'less_or_equal': function(op1,op2){ return op1 <= op2} ,
		'greater': function(op1,op2){ return op1 > op2} ,
		'greater_or_equal': function(op1,op2){ return op1 >= op2} ,
		'in': function(op1,op2){ return $.inArray(op2,op1) !== -1} ,
		'not_in': function(op1,op2){ return $.inArray(op2,op1) === -1} ,
		'is_not_null': function(op1,op2){ return (op1 != undefined && op1 != null)} ,
		'is_null': function(op1,op2){ return (op1 == undefined || op1 == null)} ,
	}
	constructor(
		protected http: HttpClient,
		protected comObj: CommonObject,
		protected env: EnvService,
		) { 
			// tslint:disable-next-line:indent
			super(http, env);
		}
	//breadcrumb
		private _get_bread_crumb_service_url = `${this.env.service_url}/bread_crumb_service`;
	    bread_crumb_service(payload) {
	      return this.http.post<any>(this._get_bread_crumb_service_url, payload );
	    }

	// Bizorg Service Calls
		private _get_all_biz_org_url = `${this.env.service_url}/get_all_bizorg`;
		get_all_biz_org(payload) {
			return this.http.post<any>(this._get_all_biz_org_url, payload);
		}

		public _get_all_bizorg_v2_url = `${this.env.service_url}/get_all_bizorg_v2`;
		get_all_bizorg_v2(payload) {
			return this.http.post<any>(this._get_all_bizorg_v2_url, payload);
		}

		public _get_all_bizorg_v3_url = `${this.env.service_url}/get_all_bizorg_v3`;
		get_all_bizorg_v3(payload) {
			return this.http.post<any>(this._get_all_bizorg_v3_url, payload);
		}

		private _get_all_biz_orgs_with_unallocated_url = `${this.env.service_url}/get_all_biz_orgs_with_unallocated`;
		get_all_biz_orgs_with_unallocated(payload) {
			return this.http.post<any>(this._get_all_biz_orgs_with_unallocated_url, payload);
		}

		private _create_biz_org_url = `${this.env.service_url}/create_bizorg`;
		create_new_bizorg(payload) {
			return this.http.post<any>(this._create_biz_org_url, payload);
		}

		private _get_update_bizorg_url = `${this.env.service_url}/get_update_bizorg`;
		get_update_bizorg(payload) {
			return this.http.post<any>(this._get_update_bizorg_url, payload);
		}

		private _get_employee_account_bizorg_list_url = `${this.env.service_url}/get_employee_account_bizorg_list`;
		get_employee_account_bizorg_list(payload) {
			return this.http.post<any>(this._get_employee_account_bizorg_list_url, payload);
		}

		private _update_employee_bizorg_url = `${this.env.service_url}/update_employee_bizorg`;
		update_employee_bizorg(payload) {
			return this.http.post<any>(this._update_employee_bizorg_url, payload);
		}

		private get_internal_org_employee_div_history_url = `${this.env.service_url}/get_internal_org_employee_div_history`;
		get_internal_org_employee_div_history(payload) {
			return this.http.post<any>(this.get_internal_org_employee_div_history_url, payload);
		}

		private terminate_employee_url = `${this.env.service_url}/terminate_employee`;
		terminate_employee(payload) {
			return this.http.post<any>(this.terminate_employee_url, payload);
		}

	// Classification Service Calls
		private get_all_classification_type_url = `${this.env.service_url}/get_all_classification_type`;
		get_all_classification_type(payload) {
			return this.http.post<any>(this.get_all_classification_type_url, payload);
		}

		private get_classification_value_data_url = `${this.env.service_url}/get_classification_value_data`;
		get_classification_value_data(payload) {
			return this.http.post<any>(this.get_classification_value_data_url, payload);
		}

		private add_classification_value_url = `${this.env.service_url}/add_classification_value`;
		add_classification_value(payload) {
			return this.http.post<any>(this.add_classification_value_url, payload);
		}

		private remove_classification_url = `${this.env.service_url}/remove_classification`;
		remove_classification(payload) {
			return this.http.post<any>(this.remove_classification_url, payload);
		}

		private update_classification_value_url = `${this.env.service_url}/update_classification_value`;
		update_classification_value(payload) {
			return this.http.post<any>(this.update_classification_value_url, payload);
		}

		private _classification_url = `${this.env.service_url}/create_classification`;
		post_classification_data(payload) {
			return this.http.post<any>(this._classification_url, payload);
		}

		private _get_particular_classification_data_url = `${this.env.service_url}/get_classification_value_data_dropdown`;
		get_particular_classification_data(payload) {
			return this.http.post<any>(this._get_particular_classification_data_url, payload);
		}

	// Divorg Service Calls
		private _create_div_org_url = `${this.env.service_url}/add_division`;
		create_new_divorg(payload) {
			return this.http.post<any>(this._create_div_org_url, payload);
		}

		private _get_update_divorg_url = `${this.env.service_url}/get_update_division`;
		get_update_divorg(payload) {
			return this.http.post<any>(this._get_update_divorg_url, payload);
		}

		private _get_all_divisions_data_url = `${this.env.service_url}/get_all_divisions_data`;
		get_all_divisions_data(payload) {
			return this.http.post<any>(this._get_all_divisions_data_url, payload);
		}

	// Work Groups Calls
		private _get_all_workgroups_url = `${this.env.service_url}/get_all_workgroups`;
		get_all_workgroups(payload) {
			return this.http.post<any>(this._get_all_workgroups_url, payload);
		}

		private get_user_workgroups_url = `${this.env.service_url}/get_user_workgroups`;
		get_user_workgroups(payload) {
			return this.http.post<any>(this.get_user_workgroups_url, payload);
		}

		private get_users_list_url = `${this.env.service_url}/get_all_users`;
		get_users_list(payload) {
			return this.http.post<any>(this.get_users_list_url, payload);
		}

		private _get_workgroup_url = `${this.env.service_url}/get_workgroup_data`;
		get_workgroup_data(payload) {
			return this.http.post<any>(this._get_workgroup_url, payload);
		}
		
		private _update_workgroup_url = `${this.env.service_url}/update_workgroup`;
		update_workgroup(payload) {
			return this.http.post<any>(this._update_workgroup_url, payload);
		}

		private get_default_workgroup_url = `${this.env.service_url}/check_user_default_workgroup`;
		get_default_workgroup(payload) {
			return this.http.post<any>(this.get_default_workgroup_url, payload);
		}

	// Sites Service Calls
		private _create_site_url = `${this.env.service_url}/add_site`;
		create_new_site(payload) {
			return this.http.post<any>(this._create_site_url, payload);
		}

		private _get_update_site_url = `${this.env.service_url}/get_update_site`;
		get_update_site(payload) {
			return this.http.post<any>( this._get_update_site_url , payload);
		}
		
		private _get_site_contacts_url = `${this.env.service_url}/get_contact_person_data`;
		get_site_contacts(payload) {
			return this.http.post<any>( this._get_site_contacts_url , payload);
		}

		private get_details_of_unallocated_contact_url = `${this.env.service_url}/get_contact_person_data_unallocated`;
		get_details_of_unallocated_contact(payload) {
			return this.http.post<any>( this.get_details_of_unallocated_contact_url , payload);
		}

		private _add_site_contact_url = `${this.env.service_url}/add_site_contact`;
		create_site_contact(payload) {
			return this.http.post<any>( this._add_site_contact_url , payload);
		}	

		private _update_site_contact_url = `${this.env.service_url}/update_site_contact`;
		update_site_contact(payload) {
			return this.http.post<any>( this._update_site_contact_url , payload);
		}
		
		private update_contact_to_site_url = `${this.env.service_url}/update_contact_to_site`;
		update_contact_to_site(payload) {
			return this.http.post<any>( this.update_contact_to_site_url , payload);
		}	

		private _remove_site_contact_url = `${this.env.service_url}/remove_site_contact`;
		remove_site_contact(payload) {
			return this.http.post<any>( this._remove_site_contact_url , payload);
		}	

		private _remove_site_url = `${this.env.service_url}/remove_site`;
		remove_site_from_employer(payload) {
			return this.http.post<any>( this._remove_site_url , payload);
		}	

		private _get_all_sites_data_url = `${this.env.service_url}/get_all_sites_data`;
		get_all_sites_data(payload) {
			return this.http.post<any>(this._get_all_sites_data_url, payload);
		}

		private _get_all_site_contacts_url = `${this.env.service_url}/get_all_site_contacts`;
		get_all_site_contacts(payload) {
			return this.http.post<any>(this._get_all_site_contacts_url, payload);
		}

		private get_site_divs_ur = `${this.env.service_url}/get_site_divs`;
		get_site_divs(payload) {
			return this.http.post<any>(this.get_site_divs_ur, payload);
		}

		private update_site_divs_url = `${this.env.service_url}/update_site_divs`;
		update_site_divs(payload) {
			return this.http.post<any>(this.update_site_divs_url, payload);
		}

		private add_contact_to_site_url = `${this.env.service_url}/add_contact_to_site`;
		add_contact_to_site(payload) {
			return this.http.post<any>(this.add_contact_to_site_url, payload);
		}

		private get_contact_details_from_national_id_url = `${this.env.service_url}/get_contact_details_from_national_id`;
		get_contact_details_from_national_id(payload) {
			return this.http.post<any>(this.get_contact_details_from_national_id_url, payload);
		}

	// Employee Service Calls
		private _get_division_employee_url = `${this.env.service_url}/get_all_corporate_employees`;
		get_division_employee(payload) {
			return this.http.post<any>( this._get_division_employee_url , payload);
		}
		private get_employee_details_from_bizorg_divorg_url = `${this.env.service_url}/get_employee_details_from_bizorg_divorg`;
		get_employee_details_from_bizorg_divorg(payload) {
			return this.http.post<any>( this.get_employee_details_from_bizorg_divorg_url , payload);
		}


		private _search_internal_org_employee_url = `${this.env.service_url}/search_internal_org_employee`;
		search_internal_org_employee(payload) {
		// search_internal_org_employee= (): Observable<any> => {
		// 	return  Observable.create((observer) => {
		// 		var data =	[{
		// 			"emp_id": 23,
		// 			"emp_name": "Varun Dhariyal"
		// 		},{
		// 			"emp_id": 2,
		// 			"emp_name": "Var"
		// 		}];
		// 		observer.next(data)
		// 	})
			return this.http.post<any>( this._search_internal_org_employee_url , payload);
		}

		// private get_contact_details_for_employee_url = `${this.env.service_url}/get_contact_details_for_employee`;
		// get_contact_details_for_employee(payload) {
		// 	return this.http.post<any>( this.get_contact_details_for_employee_url , payload);
		// }

		private get_employee_details_from_employee_id_url = `${this.env.service_url}/get_employee_details_from_employee_id`;
		get_employee_details_from_employee_id(payload) {
			return this.http.post<any>( this.get_employee_details_from_employee_id_url , payload);
		}

		private get_employee_products_from_employee_id_url = `${this.env.service_url}/get_employee_products_from_employee_id`;
		get_employee_products_from_employee_id(payload) {
			return this.http.post<any>( this.get_employee_products_from_employee_id_url , payload);
		}

		private get_employee_details_from_national_id_url = `${this.env.service_url}/get_employee_details_from_national_id`;
		get_employee_details_from_national_id(payload) {
			return this.http.post<any>( this.get_employee_details_from_national_id_url , payload);
		}

		private get_employee_details_from_passport_number_url = `${this.env.service_url}/get_employee_details_from_passport_number`;
		get_employee_details_from_passport_number(payload) {
			return this.http.post<any>( this.get_employee_details_from_passport_number_url , payload);
		}
		
		private add_employee_url = `${this.env.service_url}/add_employee`;
		add_employee(payload) {
			return this.http.post<any>( this.add_employee_url , payload);
		}

		private get_product_house_map_url = `${this.env.service_url}/get_product_house_map`;
		get_product_house_map(payload) {
			return this.http.post<any>( this.get_product_house_map_url , payload);
		}

		private add_prod_to_biz_url = `${this.env.service_url}/add_prod_to_biz`;
		add_prod_to_biz(payload) {
			return this.http.post<any>( this.add_prod_to_biz_url , payload);
		}

		private remove_prod_from_biz_url = `${this.env.service_url}/remove_prod_from_biz`;
		remove_prod_from_biz(payload) {
			return this.http.post<any>( this.remove_prod_from_biz_url , payload);
		}

		private make_employee_vip_url = `${this.env.service_url}/make_employee_vip`;
		make_employee_vip(payload) {
			return this.http.post<any>( this.make_employee_vip_url , payload);
		}

		private get_employee_master_data_url = `${this.env.service_url}/get_employee_master_data`;
		get_employee_master_data(payload) {
			return this.http.post<any>( this.get_employee_master_data_url , payload);
		}		

	// Tree service calls
		private remove_division_url = `${this.env.service_url}/remove_division`;
		remove_division(payload) {
			return this.http.post<any>( this.remove_division_url , payload);
		}

	// Internal Organisation service calls
		private _create_internal_org_employee_url = `${this.env.service_url}/create_internal_org_employee`;
		create_internal_org_employee(payload) {
			return this.http.post<any>( this._create_internal_org_employee_url , payload);
		}

		private _update_internal_org_employee_url = `${this.env.service_url}/update_internal_org_employee`;
		update_internal_org_employee(payload) {
			return this.http.post<any>( this._update_internal_org_employee_url , payload);
		}

		private _get_internal_org_employee_url = `${this.env.service_url}/get_internal_org_employee`;
		get_particular_internal_org_employee(payload) {
			return this.http.post<any>( this._get_internal_org_employee_url , payload);
		}

		private _remove_internal_org_employee_from_division_url = `${this.env.service_url}/remove_internal_org_employee_from_division`;
		remove_internal_org_employee_from_division(payload) {
			return this.http.post<any>( this._remove_internal_org_employee_from_division_url , payload);
		}

		private _add_internal_org_employee_to_division_url = `${this.env.service_url}/add_internal_org_employee_to_division`;
		add_internal_org_employee_to_division(payload) {
			return this.http.post<any>( this._add_internal_org_employee_to_division_url , payload);
		}	

		private _get_internal_org_div_employees_url = `${this.env.service_url}/get_internal_org_div_employees`;
		get_internal_org_employee(payload) {
			return this.http.post<any>( this._get_internal_org_div_employees_url , payload);
		}	

		private get_terminated_employees_url = `${this.env.service_url}/get_terminated_employees`;
		get_terminated_employees(payload) {
			return this.http.post<any>( this.get_terminated_employees_url , payload);
		}

		private _get_tree_biz_with_levels_url = `${this.env.service_url}/get_tree_biz_with_levels`;
		get_tree_biz_with_levels(payload) {
			return this.http.post<any>( this._get_tree_biz_with_levels_url , payload);
	    }
	    
	    private _get_internal_org_employee_heirarchy_rec_url = `${this.env.service_url}/get_internal_org_employee_heirarchy_rec`;
			get_internal_org_employee_heirarchy_rec(payload) {
				return this.http.post<any>( this._get_internal_org_employee_heirarchy_rec_url , payload);
		}

		private _get_internal_org_employee_reportees_url = `${this.env.service_url}/get_internal_org_employee_reportees`;
			get_internal_org_employee_reportees(payload) {
				return this.http.post<any>( this._get_internal_org_employee_reportees_url , payload);
		}
		
		private _get_internal_org_employee_managers_url = `${this.env.service_url}/get_internal_org_employee_managers`;
		get_internal_org_employee_managers(payload) {
				return this.http.post<any>( this._get_internal_org_employee_managers_url , payload);
		}
		
		private _get_internal_org_employee_certs_url = `${this.env.service_url}/get_internal_org_employee_certs`;
		get_internal_org_employee_certs(payload) {
				return this.http.post<any>( this._get_internal_org_employee_certs_url , payload);
		}
		
		private _get_internal_org_employee_contacts_url = `${this.env.service_url}/get_internal_org_employee_contacts`;
		get_internal_org_employee_contacts(payload) {
				return this.http.post<any>( this._get_internal_org_employee_contacts_url , payload);
	    }
	    
	    private _create_internal_org_employee_connection_url = `${this.env.service_url}/create_internal_org_employee_connection`;
		create_internal_org_employee_connection(payload) {
			return this.http.post<any>( this._create_internal_org_employee_connection_url , payload);
		}

		private _remove_internal_org_employee_connection_url = `${this.env.service_url}/remove_internal_org_employee_connection`;
		remove_internal_org_employee_connection(payload) {
			return this.http.post<any>( this._remove_internal_org_employee_connection_url , payload);
		}

		private remove_all_internal_org_employees_from_division_url = `${this.env.service_url}/remove_all_internal_org_employees_from_division`;
		remove_all_internal_org_employees_from_division(payload) {
			return this.http.post<any>( this.remove_all_internal_org_employees_from_division_url , payload);
		}   

		private _create_internal_org_certificate_url = `${this.env.service_url}/create_internal_org_certificate`;
		create_internal_org_certificate(payload) {
			return this.http.post<any>( this._create_internal_org_certificate_url , payload);
		}   
	
		private _update_certificate_data_url = `${this.env.service_url}/update_certificate_data`;
		update_certificate_data(payload) {
			return this.http.post<any>( this._update_certificate_data_url , payload);
		}
	
		private _update_contact_data_url = `${this.env.service_url}/add_internal_contacts`;
		update_contact_data(payload) {
			return this.http.post<any>( this._update_contact_data_url , payload);
		}
	
		private get_emp_count_for_nonleaf_url = `${this.env.service_url}/get_internal_org_div_employees_count`;
		get_emp_count_for_nonleaf(payload) {
			return this.http.post<any>( this.get_emp_count_for_nonleaf_url , payload);
		}
	
		private get_site_contact_count_url = `${this.env.service_url}/get_site_contact_count`;
		get_site_contact_count(payload) {
			return this.http.post<any>( this.get_site_contact_count_url , payload);
		}
	
		private remove_all_contacts_from_site_url = `${this.env.service_url}/remove_all_contacts_from_site`;
		remove_all_contacts_from_site(payload) {
			return this.http.post<any>( this.remove_all_contacts_from_site_url , payload);
		}


		
		private update_division_communications_url = `${this.env.service_url}/update_division_communications`;
		update_division_communications(payload) {
			return this.http.post<any>( this.update_division_communications_url , payload);
		}
		private update_site_communications_url = `${this.env.service_url}/update_site_communications`;
		update_site_communications(payload) {
			return this.http.post<any>( this.update_site_communications_url , payload);
		}
		
		private get_communication_details_for_division_url = `${this.env.service_url}/get_communication_details_for_division`;
		get_communication_details_for_division(payload) {
			return this.http.post<any>( this.get_communication_details_for_division_url , payload);
		}

		private get_bizorg_communication_details_url = `${this.env.service_url}/get_bizorg_communication_details`;
		get_bizorg_communication_details(payload) {
			return this.http.post<any>( this.get_bizorg_communication_details_url , payload);
		}
		
	
	// Workgroup Services
		private _create_workgroup_url = `${this.env.service_url}/create_workgroup`;
		create_workgroup(payload) {
			return this.http.post<any>( this._create_workgroup_url , payload);
		}

		private _create_ticket_url = `${this.env.service_url}/?`;
		create_ticket(payload) {
			return this.http.post<any>( this._create_ticket_url , payload);
		}   

		private _get_workgroup_tickets_url = `${this.env.service_url}/get_workgroup_case_list`;
		get_workgroup_tickets(payload) {
			return this.http.post<any>( this._get_workgroup_tickets_url , payload);
		}   
	
		private _get_workgroup_member_list_url = `${this.env.service_url}/get_workgroup_member_list`;
		get_workgroup_member_list(payload) {
			return this.http.post<any>( this._get_workgroup_member_list_url , payload);
		}   
		private _get_inactive_workgroup_member_list_url = `${this.env.service_url}/get_inactive_workgroup_members`;
		get_inactive_workgroup_member_list(payload) {
			return this.http.post<any>( this._get_inactive_workgroup_member_list_url , payload);
		}   

		private _create_workgroup_user_url = `${this.env.service_url}/create_workgroup_user`;
		create_workgroup_user(payload) {
			return this.http.post<any>( this._create_workgroup_user_url , payload);
		}   	

		private _update_workgroup_user_url = `${this.env.service_url}/update_workgroup_user`;
		update_workgroup_user(payload) {
			return this.http.post<any>( this._update_workgroup_user_url , payload);
		}  	
	
	// Case Management Services
		private get_ticket_data_url = `${this.env.service_url}/get_tickets_classification`;
		get_ticket_data(payload) {
			return this.http.post<any>( this.get_ticket_data_url , payload)
		}

		private get_feedback_url = `${this.env.service_url}/get_feedback`;
		get_feedback(payload) {
			return this.http.post<any>( this.get_feedback_url , payload)
		}

		private get_bizorg_employee_list_url = `${this.env.service_url}/get_bizorg_employee_list`;
		get_bizorg_employee_list(payload) {
			return this.http.post<any>( this.get_bizorg_employee_list_url , payload)
		}

		private create_case_url = `${this.env.service_url}/create_case`;
		create_case(payload) {
			return this.http.post<any>( this.create_case_url , payload)
		}

		private create_child_case_url = `${this.env.service_url}/create_child_case`;
		create_child_case(payload) {
			return this.http.post<any>( this.create_child_case_url , payload)
		}

		private update_case_url = `${this.env.service_url}/update_case`;
		update_case(payload) {
			return this.http.post<any>( this.update_case_url , payload)
		}

		private add_case_notes_url = `${this.env.service_url}/add_case_notes`;
		add_case_notes(payload) {
			return this.http.post<any>( this.add_case_notes_url , payload)
		}

		private get_closed_workflow_objects_dynamic_filter_url = `${this.env.service_url}/get_closed_workflow_objects_dynamic_filter`
		get_closed_workflow_objects_dynamic_filter(payload){
			return this.http.post<any>( this.get_closed_workflow_objects_dynamic_filter_url , payload)
		}
		// private get_case_notes_url = `${this.env.service_url}/get_case_notes`;
		// get_case_notes(payload) {
		// 	return this.http.post<any>( this.get_case_notes_url , payload)
		// }

		private get_case_classification_dropdowns_url = `${this.env.service_url}/get_case_classification_dropdowns`;
		get_case_classification_dropdowns(payload) {
			return this.http.post<any>( this.get_case_classification_dropdowns_url , payload)
		}

		private get_employee_communication_details_url = `${this.env.service_url}/get_employee_communication_details`;
		get_employee_communication_details(payload) {
			return this.http.post<any>( this.get_employee_communication_details_url , payload)
		}

		private add_employee_communication_for_case_url = `${this.env.service_url}/add_employee_communication_for_case`;
		add_employee_communication_for_case(payload) {
			return this.http.post<any>( this.add_employee_communication_for_case_url , payload)
		}

		private update_employee_communication_details_url = `${this.env.service_url}/update_employee_communication_details`;
		update_employee_communication_details(payload) {
			return this.http.post<any>( this.update_employee_communication_details_url , payload)
		}

		private update_contact_details_using_case_url = `${this.env.service_url}/update_contact_details_using_case`;
		update_contact_details_using_case(payload) {
			return this.http.post<any>( this.update_contact_details_using_case_url , payload)
		}

		private update_contact_person_contact_for_case_url = `${this.env.service_url}/update_contact_person_contact_for_case`;
		update_contact_person_contact_for_case(payload) {
			return this.http.post<any>( this.update_contact_person_contact_for_case_url , payload)
		}

		private dispatch_case_url = `${this.env.service_url}/dispatch_case`;
		dispatch_case(payload) {
			return this.http.post<any>( this.dispatch_case_url , payload)
		}

		private dispatch_case_by_rules_url = `${this.env.service_url}/dispatch_case_by_rules`;
		dispatch_case_by_rules(payload) {
			return this.http.post<any>( this.dispatch_case_by_rules_url , payload)
		}

		private accept_case_url = `${this.env.service_url}/accept_case`;
		accept_case(payload) {
			return this.http.post<any>( this.accept_case_url , payload)
		}

		private reject_case_url = `${this.env.service_url}/reject_case`;
		reject_case(payload) {
			return this.http.post<any>( this.reject_case_url , payload)
		}

		private yank_case_url = `${this.env.service_url}/yank_case`;
		yank_case(payload) {
			return this.http.post<any>( this.yank_case_url , payload)
		}

		private assign_case_url = `${this.env.service_url}/assign_case`;
		assign_case(payload) {
			return this.http.post<any>( this.assign_case_url , payload)
		}

		private reopen_case_url = `${this.env.service_url}/reopen_case`;
		reopen_case(payload) {
			return this.http.post<any>( this.reopen_case_url , payload)
		}

		private close_case_url = `${this.env.service_url}/close_case`;
		close_case(payload) {
			return this.http.post<any>( this.close_case_url , payload)
		}

		// private get_case_audits_url = `${this.env.service_url}/get_case_audits`;
		// get_case_audits(payload) {
		// 	return this.http.post<any>( this.get_case_audits_url , payload)
		// }

		private get_contact_details_for_employee_url = `${this.env.service_url}/get_contact_details_for_employee`;
		get_contact_details_for_employee(payload) {
			return this.http.post<any>( this.get_contact_details_for_employee_url , payload)
		}

		private search_employee_url = `${this.env.service_url}/search_employee`;
		search_employee(payload) {
			return this.http.post<any>( this.search_employee_url , payload)
		}

		private get_employee_products_and_houses_url = `${this.env.service_url}/get_employee_products_and_houses`;
		get_employee_products_and_houses(payload) {
			return this.http.post<any>( this.get_employee_products_and_houses_url , payload)
		}

	// Move to log details component	
		// private send_case_log_email_url = `${this.env.service_url}/send_case_log_email`;
		// send_case_log_email(payload) {
		// 	return this.http.post<any>( this.send_case_log_email_url , payload)
		// }

		private get_case_history_for_employee_url = `${this.env.service_url}/dce/v1/get_case_history_for_employee`;
		get_case_history_for_employee(payload) {
			return this.http.post<any>( this.get_case_history_for_employee_url , payload)
		}

		private search_contact_person_details_url = `${this.env.service_url}/search_contact_person_details`;
		search_contact_person_details(payload) {
			return this.http.post<any>( this.search_contact_person_details_url , payload)
		}

		private get_contact_persons_from_biz_org_url = `${this.env.service_url}/get_contact_persons_from_biz_org`;
		get_contact_persons_from_biz_org(payload) {
			return this.http.post<any>( this.get_contact_persons_from_biz_org_url , payload)
		}

		private get_contact_details_for_contact_person_url = `${this.env.service_url}/get_contact_details_for_contact_person`;
		get_contact_details_for_contact_person(payload) {
			return this.http.post<any>( this.get_contact_details_for_contact_person_url , payload)
		}

	// Case Management Setup Services
		private add_priority_classification_url = `${this.env.service_url}/add_priority_classification`;
		add_priority_classification(payload) {
			return this.http.post<any>( this.add_priority_classification_url , payload)
		}
		
		private update_priority_classification_url = `${this.env.service_url}/update_priority_classification`;
		update_priority_classification(payload) {
			return this.http.post<any>( this.update_priority_classification_url , payload)
		}
		private get_priority_severity_time_url = `${this.env.service_url}/get_all_priority_data`;
		get_priority_severity() {
			let data;
			return this.http.post<any>( this.get_priority_severity_time_url, data)
		}
		private update_priority_severity_time_url = `${this.env.service_url}/update_priority_severity_deadline`;
		update_priority_severity(payload) {
			return this.http.post<any>( this.update_priority_severity_time_url, payload)
		}
		private get_all_email_configuration_url = `${this.env.service_url}/get_all_email_configuration`;
		get_all_email_configuration() {
			let payload;
			return this.http.post<any>( this.get_all_email_configuration_url, payload)
		}
		private create_email_configuration_url = `${this.env.service_url}/create_email_configuration`;
		create_email_configuration(payload) {
			return this.http.post<any>( this.create_email_configuration_url, payload)
		}
		private update_email_configuration_url = `${this.env.service_url}/update_email_configuration`;
		update_email_configuration(payload) {
			return this.http.post<any>( this.update_email_configuration_url, payload)
		}

	// Product/Offering Services
		private _create_item_url = `${this.env.service_url}/create_item`;
		create_item(payload) {
			return this.http.post<any>( this._create_item_url, payload);
		}

		private update_item_url = `${this.env.service_url}/update_item`;
		update_item(payload) {
			return this.http.post<any>( this.update_item_url, payload);
		}

		private _get_offering_url = `${this.env.service_url}/get_all_items`;
		get_all_items(payload) {
			return this.http.post<any>( this._get_offering_url, payload);
		}

		private add_subitems_to_item_url = `${this.env.service_url}/add_sub_products`;
		add_subitems_to_item(payload) {
			return this.http.post<any>( this.add_subitems_to_item_url, payload);
		}

		private stock_history_url = `${this.env.service_url}/get_stock_transaction_history`;
		get_stock_history(payload) {
			return this.http.post<any>( this.stock_history_url, payload);
		}

		private add_stock_item_url = `${this.env.service_url}/add_item_stock`;
		add_stock(payload) {
			return this.http.post<any>( this.add_stock_item_url, payload);
		}

		private remove_stock_item_url = `${this.env.service_url}/remove_item_stock`;
		remove_stock(payload) {
			return this.http.post<any>( this.remove_stock_item_url, payload);
		}

		private get_vat_url = `${this.env.service_url}/get_vat`;
		get_vat(payload) {
			return this.http.post<any>( this.get_vat_url, payload);
		}
		
		private update_vat_url = `${this.env.service_url}/update_vat`;
		update_vat(payload) {
			return this.http.post<any>( this.update_vat_url, payload);
		}

		private get_product_house_url = `${this.env.service_url}/get_product_house`;
		get_product_house(payload) {
			return this.http.post<any>( this.get_product_house_url, payload);
		}

		private get_products_url = `${this.env.service_url}/get_products`;
		get_products(payload) {
			return this.http.post<any>( this.get_products_url, payload);
		}

		private insert_product_house_url = `${this.env.service_url}/insert_product_house`;
		insert_product_house(payload) {
			return this.http.post<any>( this.insert_product_house_url, payload);
		}

		private insert_product_url = `${this.env.service_url}/insert_product`;
		insert_product(payload) {
			return this.http.post<any>( this.insert_product_url, payload);
		}
		
	// RFQ Services
		private get_rfq_for_workgroup_url = `${this.env.service_url}/get_rfq_for_workgroup`;
		get_rfq_for_workgroup(payload) {
			return this.http.post<any>( this.get_rfq_for_workgroup_url, payload);
		}
		
		private create_rfq_url = `${this.env.service_url}/create_rfq`;
		create_rfq(payload) {
			return this.http.post<any>( this.create_rfq_url, payload);
		}
		
		private get_boq_data_url = `${this.env.service_url}/get_boq_data`;
		get_boq_data(payload) {
			return this.http.post<any>( this.get_boq_data_url, payload);
		}
		
		private edit_rfq_data_url = `${this.env.service_url}/edit_rfq_data`;
		edit_rfq_data(payload) {
			return this.http.post<any>( this.edit_rfq_data_url, payload);
		}
		
		private accept_rfq_url = `${this.env.service_url}/accept_rfq`;
		accept_rfq(payload) {
			return this.http.post<any>( this.accept_rfq_url, payload);
		}
		
		private reject_rfq_url = `${this.env.service_url}/reject_rfq`;
		reject_rfq(payload) {
			return this.http.post<any>( this.reject_rfq_url, payload);
		}
		
		private cancel_rfq_url = `${this.env.service_url}/cancel_rfq`;
		cancel_rfq(payload) {
			return this.http.post<any>( this.cancel_rfq_url, payload);
		}
		
		private dispatch_rfq_url = `${this.env.service_url}/dispatch_rfq`;
		dispatch_rfq(payload) {
			return this.http.post<any>( this.dispatch_rfq_url, payload);
		}
	
		private get_tree_biz_site_with_levels_url = `${this.env.service_url}/get_tree_biz_site_with_levels`;
		get_tree_biz_site_with_levels(payload) {
			return this.http.post<any>( this.get_tree_biz_site_with_levels_url, payload);
		}
	
		private get_site_contacts_user_url = `${this.env.service_url}/get_site_contacts_user`;
		get_site_contacts_user(payload) {
			return this.http.post<any>( this.get_site_contacts_user_url, payload);
		}

		private get_site_contacts_persons_url = `${this.env.service_url}/get_site_contacts_persons`;
		get_site_contacts_persons(payload) {
			return this.http.post<any>( this.get_site_contacts_persons_url, payload);
		}
	
		private yank_rfq_url = `${this.env.service_url}/yank_rfq`;
		yank_rfq(payload) {
			return this.http.post<any>( this.yank_rfq_url, payload);
		}
	
		private assign_rfq_url = `${this.env.service_url}/assign_rfq`;
		assign_rfq(payload) {
			return this.http.post<any>( this.assign_rfq_url, payload);
		}
	
	// Common Workflow Services
		private get_workflow_objects_for_wrkgrp_url = `${this.env.service_url}/get_workflow_objects_for_wrkgrp`;
		get_workflow_objects_for_wrkgrp(payload) {
			return this.http.post<any>( this.get_workflow_objects_for_wrkgrp_url, payload);
		}
		
		private get_all_workflow_objects_url = `${this.env.service_url}/get_workflow_objects_for_wrkgrp`;
		get_all_workflow_objects(payload) {
			return this.http.post<any>( this.get_all_workflow_objects_url, payload);
		}
		
		private get_all_child_workflow_objects_url = `${this.env.service_url}/dce/v1/get_all_child_workflow_objects`;
		get_all_child_workflow_objects(payload) {
			return this.http.post<any>( this.get_all_child_workflow_objects_url, payload);
		}
		
		private get_workflow_object_data_url = `${this.env.service_url}/get_workflow_object_data`;
		get_workflow_object_data(payload) {
			return this.http.post<any>( this.get_workflow_object_data_url, payload);
		}

		private get_closed_workflow_objects_url = `${this.env.service_url}/get_closed_workflow_objects`;
		get_closed_workflow_objects(payload) {
			return this.http.post<any>( this.get_closed_workflow_objects_url, payload);
		}

		private get_workflow_log_url = `${this.env.service_url}/get_workflow_log`;
		get_workflow_log(payload) {
			return this.http.post<any>( this.get_workflow_log_url , payload)
		}

		private get_notification_log_url = `${this.env.service_url}/get_notification_log`;
		get_notification_log(payload) {
			return this.http.post<any>( this.get_notification_log_url , payload)
		}

		private get_copy_notificaiton_definition_url = `${this.env.service_url}/copy_notificaiton_definition`;
		copy_notificaiton_definition(payload) {
			return this.http.post<any>( this.get_copy_notificaiton_definition_url , payload)
		}

		private get_workflow_audit_url = `${this.env.service_url}/get_workflow_audit`;
		get_workflow_audit(payload) {
			return this.http.post<any>( this.get_workflow_audit_url , payload)
		}

		private get_workflow_obj_children_url = `${this.env.service_url}/dce/v1/get_workflow_obj_children`;
		get_workflow_obj_children(payload) {
			return this.http.post<any>( this.get_workflow_obj_children_url , payload)
		}

		private get_workflow_notes_url = `${this.env.service_url}/get_workflow_notes`;
		get_workflow_notes(payload) {
			return this.http.post<any>( this.get_workflow_notes_url , payload)
		}

		private get_workflow_notes_documents_url = `${this.env.service_url}/dce/v1/get_workflow_notes_documents`;
		get_workflow_notes_documents(payload){
			return this.http.post<any>( this.get_workflow_notes_documents_url , payload)
		}

		accept(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const accept_url = `${this.env.service_url}/accept_${wf_act}`;
			return this.http.post<any>(accept_url, payload);
		}

		reject(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const reject_url = `${this.env.service_url}/reject_${wf_act}`;
			return this.http.post<any>(reject_url, payload);
		}

		assign(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const assign_url = `${this.env.service_url}/assign_${wf_act}`;
			return this.http.post<any>(assign_url, payload);
		}

		yank(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const yank_url = `${this.env.service_url}/yank_${wf_act}`;
			return this.http.post<any>(yank_url, payload);
		}

		dispatch_by_rules(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const dispatch_by_rules_url = `${this.env.service_url}/dispatch_${wf_act}_by_rules`;
			return this.http.post<any>(dispatch_by_rules_url, payload);
		}

		dispatch(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const dispatch_url = `${this.env.service_url}/dispatch_${wf_act}`;
			return this.http.post<any>(dispatch_url, payload);
		}

		close(wf_act_type, payload) {
			const wf_act = wf_act_type.toLowerCase();
			const close_url = `${this.env.service_url}/close_${wf_act}`;
			return this.http.post<any>(close_url, payload);
		}

	// Quotes Services
		private get_quotes_for_workgroup_url = `${this.env.service_url}/get_quotes_for_workgroup`;
		get_quotes_for_workgroup(payload) {
			return this.http.post<any>( this.get_quotes_for_workgroup_url, payload);
		}

		private get_rfq_data_url = `${this.env.service_url}/get_rfq_data`;
		get_rfq_data(payload) {
			return this.http.post<any>( this.get_rfq_data_url, payload);
		}
	
		private create_quote_url = `${this.env.service_url}/create_quote`;
		create_quote(payload) {
			return this.http.post<any>( this.create_quote_url, payload);
		}
		
		private update_quote_url = `${this.env.service_url}/update_quote`;
		update_quote(payload) {
			return this.http.post<any>( this.update_quote_url, payload);
		}
		
		private send_quote_url = `${this.env.service_url}/dispatch_quote`;
		send_quote(payload) {
			return this.http.post<any>( this.send_quote_url, payload);
		}
		
		private accept_quote_url = `${this.env.service_url}/accept_quote`;
		accept_quote(payload) {
			return this.http.post<any>( this.accept_quote_url, payload);
		}
		
		private reject_quote_url = `${this.env.service_url}/reject_quote`;
		reject_quote(payload) {
			return this.http.post<any>( this.reject_quote_url, payload);
		}
		
		private cancel_quote_url = `${this.env.service_url}/cancel_quote`;
		cancel_quote(payload) {
			return this.http.post<any>( this.cancel_quote_url, payload);
		}

		private assign_quote_url = `${this.env.service_url}/assign_quote`;
		assign_quote(payload) {
			return this.http.post<any>( this.assign_quote_url, payload);
		}

		private yank_quote_url = `${this.env.service_url}/yank_quote`;
		yank_quote(payload) {
			return this.http.post<any>( this.yank_quote_url, payload);
		}
		
	// Order Services
		private create_order_url = `${this.env.service_url}/create_order`;
		create_order(payload) {
			return this.http.post<any>( this.create_order_url, payload);
		}
		
		private cancel_order_url = `${this.env.service_url}/cancel_order`;
		cancel_order(payload) {
			return this.http.post<any>( this.cancel_order_url, payload);
		}
		
		private accept_order_url = `${this.env.service_url}/accept_order`;
		accept_order(payload) {
			return this.http.post<any>( this.accept_order_url, payload);
		}
		
		private reject_order_url = `${this.env.service_url}/reject_order`;
		reject_order(payload) {
			return this.http.post<any>( this.reject_order_url, payload);
		}
		
		private send_order_url = `${this.env.service_url}/dispatch_order`;
		send_order(payload) {
			return this.http.post<any>( this.send_order_url, payload);
		}
		
		private update_order_url = `${this.env.service_url}/update_order`;
		update_order(payload) {
			return this.http.post<any>( this.update_order_url, payload);
		}
		
		private close_order_url = `${this.env.service_url}/close_order`;
		close_order(payload) {
			return this.http.post<any>( this.close_order_url, payload);
		}

		private yank_order_url = `${this.env.service_url}/yank_order`;
		yank_order(payload) {
			return this.http.post<any>( this.yank_order_url, payload);
		}
		
		private assign_order_url = `${this.env.service_url}/assign_order`;
		assign_order(payload) {
			return this.http.post<any>( this.assign_order_url, payload);
		}
		
	// Notification Services
		private get_notification_definition_url = `${this.env.service_url}/get_notification_definition`;
		get_notification_definition(payload) {
			return this.http.post<any>( this.get_notification_definition_url, payload);
		}
		private get_workflow_action_decision_data_url = `${this.env.service_url}/get_workflow_action_decision_data`;
		get_workflow_action_decision_data(payload) {
			return this.http.post<any>( this.get_workflow_action_decision_data_url, payload);
		}
		
		private get_all_notification_definition_url = `${this.env.service_url}/get_all_notification_definition`;
		get_all_notification_definition(payload) {
			return this.http.post<any>( this.get_all_notification_definition_url, payload);
		}
		
		private create_notificaiton_definition_url = `${this.env.service_url}/create_notificaiton_definition`;
		create_notificaiton_definition(payload) {
			return this.http.post<any>( this.create_notificaiton_definition_url, payload);
		}
		
		private update_notificaiton_definition_url = `${this.env.service_url}/update_notificaiton_definition`;
		update_notificaiton_definition(payload) {
			return this.http.post<any>( this.update_notificaiton_definition_url, payload);
		}
		private get_notification_view_cols_url = `${this.env.service_url}/get_notification_view_cols`;
		get_notification_view_cols(payload){
			return this.http.post<any>( this.get_notification_view_cols_url, payload);
		}
		private get_notification_decision_data_url = `${this.env.service_url}/get_notification_decision_data`;
		get_notification_decision_data(payload) {
			return this.http.post<any>( this.get_notification_decision_data_url, payload);
		}


	// Task Services
		private get_task_group_list_url = `${this.env.service_url}/get_task_group_list`;
		get_task_group_list(payload) {
			return this.http.post<any>( this.get_task_group_list_url, payload);
		}
		private get_workflow_object_data_for_user_url = `${this.env.service_url}/get_workflow_object_data_for_user`;
		get_workflow_object_data_for_user(payload) {
			return this.http.post<any>( this.get_workflow_object_data_for_user_url, payload);
		}
		private update_task_url = `${this.env.service_url}/update_task`;
		update_task(payload){
			return this.http.post<any>( this.update_task_url, payload);
		}
		private create_task_group_url = `${this.env.service_url}/create_task_group`;
		create_task_group(payload) {
			return this.http.post<any>( this.create_task_group_url, payload);
		}
		
		private update_task_group_url = `${this.env.service_url}/update_task_group`;
		update_task_group(payload) {
			return this.http.post<any>( this.update_task_group_url, payload);
		}
		
		private instantiate_task_group_url = `${this.env.service_url}/instantiate_task_group`;
		instantiate_task_group(payload) {
			return this.http.post<any>( this.instantiate_task_group_url, payload);
		}

		private get_instances_for_task_group_url = `${this.env.service_url}/get_task_group_instances`;
		get_instances_for_task_group(payload) {
			return this.http.post<any>( this.get_instances_for_task_group_url, payload);
		}		

		private _get_task_list_for_instance_url = `${this.env.service_url}/get_task_list_for_instance`;
		get_task_list_for_instance(payload) {
			return this.http.post<any>( this._get_task_list_for_instance_url, payload);
		}		

		private _accept_task_url = `${this.env.service_url}/accept_task`;
		accept_task(payload) {
			return this.http.post<any>( this._accept_task_url, payload);
		}	

		private _dispatch_task_url = `${this.env.service_url}/dispatch_task`;
		dispatch_task(payload) {
			return this.http.post<any>( this._dispatch_task_url, payload);
		}

		private _dispatch_task_by_rules_url = `${this.env.service_url}/dispatch_task_by_rules`;
		dispatch_task_by_rules(payload) {
			return this.http.post<any>( this._dispatch_task_by_rules_url, payload);
		}

		private _reject_task_url = `${this.env.service_url}/reject_task`;
		reject_task(payload) {
			return this.http.post<any>( this._reject_task_url, payload);
		}

		private _assign_task_url = `${this.env.service_url}/assign_task`;
		assign_task(payload) {
			return this.http.post<any>( this._assign_task_url, payload);
		}

		private _yank_task_url = `${this.env.service_url}/yank_task`;
		yank_task(payload) {
			return this.http.post<any>( this._yank_task_url, payload);
		}		

		private start_task_url = `${this.env.service_url}/start_task`;
		start_task(payload) {
			return this.http.post<any>( this.start_task_url, payload);
		}		

		private complete_task_url = `${this.env.service_url}/complete_task`;
		complete_task(payload) {
			return this.http.post<any>( this.complete_task_url, payload);
		}		

		private close_task_url = `${this.env.service_url}/close_task`;
		close_task(payload) {
			return this.http.post<any>( this.close_task_url, payload);
		}		

		private task_percentage_complete_url = `${this.env.service_url}/task_percentage_complete`;
		task_percentage_complete(payload) {
			return this.http.post<any>( this.task_percentage_complete_url, payload);
		}		

		private add_task_note_url = `${this.env.service_url}/add_task_note`;
		add_task_note(payload) {
			return this.http.post<any>( this.add_task_note_url, payload);
		}

		private update_task_status_url = `${this.env.service_url}/update_task_status`;
		update_task_status(payload) {
			return this.http.post<any>( this.update_task_status_url, payload);
		}

		private _create_task_url = `${this.env.service_url}/create_task`;
		create_task(payload) {
			return this.http.post<any>( this._create_task_url, payload);
		}		

		// private get_task_notes_url = `${this.env.service_url}/get_task_notes`;
		// get_task_notes(payload) {
		// 	return this.http.post<any>( this.get_task_notes_url , payload)
		// }

		// private get_task_audits_url = `${this.env.service_url}/get_task_audits`;
		// get_task_audits(payload) {
		// 	return this.http.post<any>( this.get_task_audits_url , payload)
		// }

		private get_gannt_chart_url = `${this.env.service_url}/get_gannt_chart`;
		get_gannt_chart(payload) {
			return this.http.post<any>( this.get_gannt_chart_url , payload)
		}
		private get_task_classification_dropdown_url = `${this.env.service_url}/get_task_classification_dropdown`;
		get_task_classification_dropdown(payload){
			return this.http.post<any>( this.get_task_classification_dropdown_url , payload)			
		}

		private remove_task_instance_url = `${this.env.service_url}/remove_task_instance`;
		remove_task_instance(payload){
			return this.http.post<any>( this.remove_task_instance_url , payload)
		}

		private force_remove_task_instance_url = `${this.env.service_url}/force_remove_task_instance`;
		force_remove_task_instance(payload){
			return this.http.post<any>( this.force_remove_task_instance_url , payload)
		}

		private get_gannt_chart_pdf_url = `${this.env.service_url}/get_gannt_chart_pdf`;
		get_gannt_chart_pdf(payload){
			return this.http.post<any>( this.get_gannt_chart_pdf_url , payload)
		}

	// Document Service call 
		private _get_document_url = `${this.env.service_url}/get_quote_documents`;
		get_quote_document(payload) {
			return this.http.post<any>( this._get_document_url, payload);
		}		

		private _get_order_document_url = `${this.env.service_url}/get_order_documents`;
		get_order_document(payload) {
			return this.http.post<any>( this._get_order_document_url, payload);
		}		

	// Bell Icon Notification Service call
		private _mark_read_notifications_url = `${this.env.service_url}/mark_read_notifications`;
		mark_read_notifications(payload) {
			return this.http.post<any>( this._mark_read_notifications_url, payload);
		}	

		private _get_unread_push_notifications_url = `${this.env.service_url}/get_unread_push_notifications`;
		get_unread_push_notifications(payload) {
			return this.http.post<any>( this._get_unread_push_notifications_url, payload);
		}	
		private enable_poll_url = `${this.env.service_url}/enable_poller`;
		enable_poll(payload) {
			return this.http.post<any>( this.enable_poll_url, payload);
		}	
		private disable_poll_url = `${this.env.service_url}/disable_poller`;
		disable_poll(payload) {
			return this.http.post<any>( this.disable_poll_url, payload);
		}		

	// Other
		private get_sidebar_tree_url = `${this.env.service_url}/get_sidebar_tree`;
		get_sidebar_tree(payload) {
			return this.http.post<any>( this.get_sidebar_tree_url, payload);
		}

		private _get_internal_user_details_refresh_url = `${this.env.service_url}/get_internal_user_details_refresh`;
		get_data_after_refresh(payload) {
			return this.http.post<any>( this._get_internal_user_details_refresh_url, payload);
		}	

		private _get_sidebar_configuration_url = `${this.env.service_url}/get_sidebar_configuration`;
		get_sidebar_configuration(payload) {
			return this.http.post<any>( this._get_sidebar_configuration_url, payload);
		}	

		private get_user_role_map_url = `${this.env.service_url}/get_user_role_map`;
		get_user_role_map(payload) {
			return this.http.post<any>( this.get_user_role_map_url, payload);
		}
		
		private change_user_password_by_admin_url = `${this.env.service_url}/change_user_password_by_admin`
		change_user_password_by_admin(payload){
			return this.http.post<any>( this.change_user_password_by_admin_url, payload);
		}
		
		private reset_password_url = `${this.env.service_url}/reset_password`
		reset_password(payload){
			return this.http.post<any>( this.reset_password_url, payload);
		}
		
		private send_forgot_password_email_url = `${this.env.service_url}/send_forgot_password_email`
		send_forgot_password_email(payload){
			return this.http.post<any>( this.send_forgot_password_email_url, payload);
		}
		
		private forgot_password_url  = `${this.env.service_url}/forgot_password`;
		forgot_password(payload){
			return this.http.post<any>( this.forgot_password_url, payload);
		}

		private check_password_availablity_url  = `${this.env.service_url}/check_password_already_used`;
		check_password_availablity(payload){
			return this.http.post<any>( this.check_password_availablity_url, payload);
		}
	
		private internal_dashboard_details_rfq_url  = `${this.env.service_url}/internal_dashboard_details_rfq`;
		internal_dashboard_details_rfq(payload){
			return this.http.post<any>( this.internal_dashboard_details_rfq_url, payload);
		}
	
		private internal_dashboard_details_quote_url  = `${this.env.service_url}/internal_dashboard_details_quote`;
		internal_dashboard_details_quote(payload){
			return this.http.post<any>( this.internal_dashboard_details_quote_url, payload);
		}
	
		private internal_dashboard_details_order_url  = `${this.env.service_url}/internal_dashboard_details_order`;
		internal_dashboard_details_order(payload){
			return this.http.post<any>( this.internal_dashboard_details_order_url, payload);
		}

		private _get_user_signature_url = `${this.env.service_url}/get_signature_from_user_id`;
		get_signature_from_user_id(payload){
			return this.http.post<any>( this._get_user_signature_url, payload);
		}
		private create_user_signature_url = `${this.env.service_url}/create_user_signature`
		create_user_signature(payload){
		       return this.http.post<any>( this.create_user_signature_url, payload);
		}

	// Workflow Action Config
		private get_all_workflow_actions_url = `${this.env.service_url}/get_all_workflow_actions`;
		get_all_workflow_actions(payload){
			return this.http.post<any>( this.get_all_workflow_actions_url, payload);
		}
		private update_workflow_actions_url = `${this.env.service_url}/update_workflow_actions`;
		update_workflow_actions(payload){
			return this.http.post<any>( this.update_workflow_actions_url, payload);
		}
		private create_workflow_actions_url = `${this.env.service_url}/create_workflow_actions`;
		create_workflow_actions(payload){
			return this.http.post<any>( this.create_workflow_actions_url, payload);
		}
	// Roles
		private get_roles_list_url = `${this.env.service_url}/get_roles_list`;
		get_roles_list(payload) {
			return this.http.post<any>( this.get_roles_list_url, payload);
		}

		private create_new_role_url = `${this.env.service_url}/create_new_role`;
		create_new_role(payload) {
			return this.http.post<any>( this.create_new_role_url, payload);
		}

		private update_sidebar_configuration_url = `${this.env.service_url}/update_sidebar_configuration`;
		update_sidebar_configuration(payload) {
			return this.http.post<any>( this.update_sidebar_configuration_url, payload);
		}

		private get_component_configuration_url = `${this.env.service_url}/get_component_configuration`;
		get_component_configuration(payload) {
			return this.http.post<any>( this.get_component_configuration_url, payload);
		}

		private update_component_configuration_url = `${this.env.service_url}/update_component_configuration`;
		update_component_configuration(payload) {
			return this.http.post<any>( this.update_component_configuration_url, payload);
		}

		private get_all_user_roles_url = `${this.env.service_url}/get_all_user_roles`;
		get_all_user_roles(payload) {
			return this.http.post<any>( this.get_all_user_roles_url, payload);
		}

		private update_user_roles_url = `${this.env.service_url}/update_user_roles`;
		update_user_roles(payload) {
			return this.http.post<any>( this.update_user_roles_url, payload);
		}
		private get_contact_contact_details_url = `${this.env.service_url}/get_contact_contact_details`;
		get_contact_contact_details(payload){
			return this.http.post<any>( this.get_contact_contact_details_url, payload);
		}
	//Dispatch create_dispatch_definition
		private create_dispatch_definition_url = `${this.env.service_url}/create_dispatch_definition`;
		create_dispatch_definition(payload){
			return this.http.post<any>( this.create_dispatch_definition_url, payload);
		}
		private get_all_dispatch_definition_url = `${this.env.service_url}/get_all_dispatch_definition`
		get_all_dispatch_definition(payload){
			return this.http.post<any>( this.get_all_dispatch_definition_url, payload);
		}
		private get_dispatch_definition_url = `${this.env.service_url}/get_dispatch_definition`
		get_dispatch_definition(payload){
			return this.http.post<any>( this.get_dispatch_definition_url, payload);
		}
		private update_dispatch_definition_url = `${this.env.service_url}/update_dispatch_definition`
		update_dispatch_definition(payload){
			return this.http.post<any>( this.update_dispatch_definition_url, payload);
		}
		private get_dispatch_decision_data_url = `${this.env.service_url}/get_dispatch_decision_data`
		get_dispatch_decision_data(payload){
			return this.http.post<any>( this.get_dispatch_decision_data_url, payload);
		}

	// Services Plans Services
		private get_service_plans_list_url = `${this.env.service_url}/?`
		get_service_plans_list(payload){
			return this.http.post<any>( this.get_service_plans_list_url, payload);
		}

		private create_service_plan_url = `${this.env.service_url}/?`
		create_service_plan(payload){
			return this.http.post<any>( this.create_service_plan_url, payload);
		}

		private update_service_plan_url = `${this.env.service_url}/?`
		update_service_plan(payload){
			return this.http.post<any>( this.update_service_plan_url, payload);
		}

		private instantiate_service_plan_url = `${this.env.service_url}/?`
		instantiate_service_plan(payload){
			return this.http.post<any>( this.instantiate_service_plan_url, payload);
		}

		private get_taskgroup_of_service_plan_url = `${this.env.service_url}/?`
		get_taskgroup_of_service_plan(payload){
			return this.http.post<any>( this.get_taskgroup_of_service_plan_url, payload);
		}

	// Common Functions
		async remove_particular_division( bus_id, div_id) {
			const payload = {
				'div_org_id': div_id,
				'biz_org_id': bus_id
			};
			const check_division = await this.remove_division(payload).toPromise();
			if (check_division.errCode == 0) {
				return true;
			} else {
				return false;
			}

		}

		//replace_empty_values
		replace_empty_values(res, keys, replace_with) {

			for(let i=0;i<res.length;i++){
				for(var j=0; j<keys.length; j++){
					if(res[i][keys[j]] == '' || res[i][keys[j]] == null){
						res[i][keys[j]] = replace_with;
					}
				}
			}

			return res;

		}

		filter_data_in_datatable(val, column_list, data) {

			let data_list = [];
			if (val) {
				val = val.toLowerCase();
			} else {
				return data_list = [...data];
			}

			if (!column_list.length) {
				return;
			}

			const rows = data.filter(function (d) {
				for (let i = 0; i <= column_list.length; i++) {
					const column = column_list[i];
					if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
						return true;
					}
				}
			});
			data_list = rows;
			return data_list;
		}

		date_formatter(event){
			let new_date = '';
			for ( var key in event) {
			  if (key == 'day') {
				new_date+= event[key];
			  } else  if (key == 'month' || key == 'year'){
				new_date+= event[key]+ '-';
			  }
			}
			return new_date;
		}

		date_validation(start_date, end_date) {
			const sdate = new Date(start_date);
			const edate = new Date(end_date);
			return (sdate.getTime() < edate.getTime()) ? true : false;
		}

		qry_builder_decision_to_backend(decision_data, data_type_map){
			var condition_map = {
				"AND": 'and',
				"OR": 'or',
				"NOT": 'not'
			};

			var operator_map = {
				"equal": '==',
				"not_equal": '!=',
				"less": '<',
				"less_or_equal": '<=',
				"greater": '>',
				"greater_or_equal": '>=',
				"is_null":"is_null",
				"is_not_null":"is_not_null",
				// 'in':'in',
				// 'not_in':'not_in',
				// 'between':'between',
				// 'not_between':'not_between',
				// 'begins_with':'begins_with',
				// 'not_begins_with':'not_begins_with',
				// 'contains':'contains',
				// 'not_contains':'not_contains',
				// 'ends_with':'ends_with',
				// 'not_ends_with':'not_ends_with',
				// 'is_empty':'is_empty',
				// 'is_not_empty':'is_not_empty',
			};

			// console.log(decision_data);
			var ret_val = {};
			var temp_list = [];
			var condition = decision_data['condition'];
			var rules = decision_data['rules'];
			var ret = {};
			var final_list = [];
			var d_type = 'str';

			for(var i=0; i<rules.length; i++){
				if(rules[i]['condition'] != undefined){
					ret = this.qry_builder_decision_to_backend(rules[i], data_type_map);
				} else {
					d_type = "str";
					if(data_type_map[rules[i]['id']]){
						d_type = data_type_map[rules[i]['id']];
					}
					temp_list.push({
						"operator": operator_map[rules[i]['operator']],
						"type": "str",	//	not required
						"data_type": data_type_map[rules[i]['id']],
						"op1": rules[i]['id'],
						"op2": rules[i]['value']
					});
				}
			}
			if(!$.isEmptyObject(ret)){
				final_list = temp_list.concat(ret);
			} else {
				final_list = temp_list;
			}

			ret_val[condition_map[condition]] = final_list;
			// console.log(condition);
			// console.log(final_list);
			// console.log(ret_val);
			// console.log("-------");
			// debugger
			return ret_val;
		}
		//Quote To RFQ Workgroup Relation
		private add_rfq_to_quote_workgroup_url = `${this.env.service_url}/add_rfq_to_quote_workgroup`;
		add_rfq_to_quote_workgroup(payload) {
			return this.http.post<any>( this.add_rfq_to_quote_workgroup_url, payload);
		}
		private get_rfq_quote_map_url = `${this.env.service_url}/get_rfq_quote_map`;
		get_rfq_quote_map() {
			return this.http.post<any>( this.get_rfq_quote_map_url,{});
		}
		process_rule(decision_data,object_data,user_id){
			// console.log(this.lambda['equal'](1,2));
			let ret_val = true;
			let temp_list = [];
			let condition = decision_data['condition'];
			let rules = decision_data['rules'];

			for(let i=0; i<rules.length; i++){
				if(rules[i]['condition'] != undefined){
					temp_list.push(this.process_rule(rules[i],object_data,user_id));
				} else {
					if(rules[i]['id'] == "DCE_SPECIAL_KEY"){
						temp_list.push(this.process_dce_special_key(rules[i],user_id,object_data))
					}else{
						temp_list.push(this.lambda[rules[i]['operator']](object_data[rules[i]['id']],rules[i]['value']));
		          	}	
				}
			}
			for (let index = 0; index < temp_list.length; index++) {
				if(condition == "AND"){
					if(!temp_list[index]){
						ret_val =  false
						break;
					}else{
						ret_val =  true
					}
				}
				if(condition == "OR"){
					if(temp_list[index]){
						ret_val =  true
						break;
					}else{
						ret_val =  false
					}
				}				
			}
			return ret_val;
		}	
		process_dce_special_key(rule,user_id,object_data){
			if(rule['value']){
				let op = rule['value'].split('$$')
				if(op[1] == 'user_id'){
					return this.lambda[rule['operator']](object_data[op[0]],user_id);
				}
			}
			return false;
		}
		onSortDT(event,data){
			const rows = [...data];
			const sort = event.sorts[0];
			rows.sort((a, b) => {
			  return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
			});
			return rows
		}

		// Pass data in array e.g ['select', 'input', 'textarea'];
		add_title_attribute(tag_list) {
			const html_tags = {
				'input': function(){
						$("input").each(function(){
							$(this).attr("title", $(this).val());
						});
				},
				'textarea': function() { 
						$("textarea").each(function(){
							$(this).attr("title", $(this).val());
						});
				},
				'select': function(){	
						$("select").each(function(){
							$(this).attr("title", $(this).find(":selected").text());
						});
				}
			}
			for(let i = 0;i< tag_list.length; i++) {
				setTimeout(() => {
					html_tags[tag_list[i]]();
				}, 200);
			}

		}
		onChanged($event){
			setTimeout(function(){
			  if(!$('.autocomplete-container').hasClass('active')){
			    $('.suggestions-container').hide()
			    $('.not-found').hide()
			  }else{
			    $('.suggestions-container').show()
			    $('.not-found').show()
			  }
			},10);
		}

		changeSortSettings(col,sort_type,_case) {
			localStorage.setItem(_case, JSON.stringify([{prop: col, dir: sort_type}]));
		}

		getSortSettings(_case) {
			if (localStorage.getItem(_case)) {
				return JSON.parse(localStorage.getItem(_case));
			}
		}

		formatDate(date) {
			var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();
	
			if (month.length < 2) 
					month = '0' + month;
			if (day.length < 2) 
					day = '0' + day;
	
			return [year, month, day].join('-');
		}
		process_selected_advice(selected_plan){
			let show_payload= {
				'basic' : [
					{'label':'Member National Id','value':selected_plan.national_id},
					{'label':'Member Full Name','value':selected_plan.mem_full_name},
					{'label':'Cell phone','value':selected_plan.comm_phone},
					{'label':'Email Address','value':selected_plan.comm_email},
					// {'label':'Advice Id','value':selected_plan.advice_id},
				],
				'client_objectives':[],
				'need_filters':[],
			}

			// Clien Objective
			if(selected_plan.client_objectives){

				if(selected_plan.client_objectives.deductibles['1']){
					show_payload['client_objectives'].push({'label':'Deductibles/ Co-Payments','value':'Pre-determined list of procedures'})
				}
				if(selected_plan.client_objectives.special_needs['0']){
					show_payload['client_objectives'].push({'label':'Special Needs','value':'Pregnancy'})
				}
				if(selected_plan.client_objectives.additional_needs != ''){
					show_payload['client_objectives'].push({'label':'Additional Needs','value':selected_plan.client_objectives.additional_needs})
				}
				let optD = ''
				for (let i in this.comObj.optical_dentistry) {
					if(selected_plan.client_objectives.optical_dentistry[i]){
						optD += this.comObj.optical_dentistry[i]+", "
					}
				}
				if(optD != ''){
					show_payload['client_objectives'].push({'label':'Optical/ Dentistry','value':optD})
				}
				let undW = ''
				for (let i in this.comObj._underwriting) {
					if(selected_plan.client_objectives.underwriting[i]){
						undW += this.comObj._underwriting[i]+", "
					}
				}
				if(undW != ''){
					show_payload['client_objectives'].push({'label':'Under Writings','value':undW})
				}
				let dTd = ''
				for (let i in this.comObj.day_to_day) {
					if(selected_plan.client_objectives.day_to_day[i]){
						dTd += this.comObj.day_to_day[i]+", "
					}
				}
				if(dTd != ''){
					show_payload['client_objectives'].push({'label':'Day-to-Day Cover','value':dTd})
				}
			}

			// Need Filters
			if(selected_plan.need_filters){
				if(selected_plan.need_filters.no_of_spouses){
					show_payload['need_filters'].push({'label':'No. of Spouses','value':selected_plan.need_filters.no_of_spouses})
				}
				if(selected_plan.need_filters.no_of_children){
					show_payload['need_filters'].push({'label':'No. of Children','value':selected_plan.need_filters.no_of_children})
				}
				if(selected_plan.need_filters.hospital){
					show_payload['need_filters'].push({'label':'Hospital','value':this.comObj.hospital[selected_plan.need_filters.hospital]})
				}
				if(selected_plan.need_filters.no_of_adult_dependants){
					show_payload['need_filters'].push({'label':'No. of Adult Dependants','value':selected_plan.need_filters.no_of_adult_dependants})
				}
				if(selected_plan.need_filters.no_of_dependents){
					show_payload['need_filters'].push({'label':'No. of Dependants','value':selected_plan.need_filters.no_of_dependents})
				}
				if(selected_plan.need_filters.day_to_day_cover){
					show_payload['need_filters'].push({'label':'Day-to-Day Cover','value':this.comObj.day_to_day_cover[selected_plan.need_filters.day_to_day_cover]})
				}
				if(selected_plan.need_filters.chronic_conditions){
					show_payload['need_filters'].push({'label':'Chronic Condition','value':selected_plan.need_filters.chronic_conditions?'Yes':'No'})
				}
				if(selected_plan.need_filters.coverage){
					show_payload['need_filters'].push({'label':'Coverage','value':this.comObj.coverage[selected_plan.need_filters.coverage]})
				}
				if(selected_plan.need_filters.day_to_day_extent){
					show_payload['need_filters'].push({'label':'Day-to-Day extent','value':this.comObj.day_to_day_extent[selected_plan.need_filters.day_to_day_extent]})
				}
			}
			return show_payload


		}

		jump_to_error() {
			setTimeout(() => {
				let invalidFields = [].slice.call(document.getElementsByClassName('app-error-class'));
				// console.log(invalidFields);
				$('html, body').animate({
					scrollTop: $(invalidFields[0]).offset().top - 130
				}, 500);
			}, 100);
		}

		select_row(dt_object_list,value_to_compare,key_to_compare){
		    if(dt_object_list.length > 0){
		      for (var i = dt_object_list.length - 1; i >= 0; i--) {
		        if(dt_object_list[i][key_to_compare] == value_to_compare){
		          return [dt_object_list[i]]
		        }
		      }
		    }
		    return []
			}

			autocomplete_value_exist(data_list,search_keyword, formNameValue) {
				if (typeof(formNameValue) == 'object'){
					for (let i =0 ;i< data_list.length;i++) {
						if (data_list[i][search_keyword] == formNameValue[search_keyword]) {
							return {status: true,value: formNameValue};
						}
					}
				} else if (typeof(formNameValue) == 'string') {
					for (let i =0 ;i< data_list.length;i++) {
						if (data_list[i][search_keyword] == formNameValue) {
							return {status: true,value: formNameValue};
						}
					}
				}
				return {status: false,value: null};
			}
}
