import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../../../../dce-service/shared.service';
import { CommonService } from 'src/app/dce-service/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faExchangeAlt, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router, Route, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event) {
    this.logout();
  }  

    notifications: any = [];
    user_name = '';
    user_email = '';
    user_full_name = '';
    recent_notifications = [];
    unread_notification = 0
    interval_obj: any;
    profiles = [];
    current_profile = {
      role_id : -1,
      role_name: ''
    };

    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService,
      private _shared: SharedService,
      private _commonService: CommonService,
      private sanitizer:DomSanitizer,
      private library: FaIconLibrary,
      private _router: Router,
      // private deviceService: DeviceDetectorService
    ) {
      library.addIcons(faExchangeAlt, faTimes, faUser );
      // this.notifications = [
      //   {
      //     icon: 'i-Speach-Bubble-6',
      //     title: 'New message',
      //     badge: '3',
      //     text: 'James: Hey! are you busy?',
      //     time: new Date(),
      //     status: 'primary',
      //     link: '/chat'
      //   },
      //   {
      //     icon: 'i-Receipt-3',
      //     title: 'New order received',
      //     badge: '$4036',
      //     text: '1 Headphone, 3 iPhone x',
      //     time: new Date('11/11/2018'),
      //     status: 'success',
      //     link: '/tables/full'
      //   },
      //   {
      //     icon: 'i-Empty-Box',
      //     title: 'Product out of stock',
      //     text: 'Headphone E67, R98, XL90, Q77',
      //     time: new Date('11/10/2018'),
      //     status: 'danger',
      //     link: '/tables/list'
      //   },
      //   {
      //     icon: 'i-Data-Power',
      //     title: 'Server up!',
      //     text: 'Server rebooted successfully',
      //     time: new Date('11/08/2018'),
      //     status: 'success',
      //     link: '/dashboard/v2'
      //   },
      //   {
      //     icon: 'i-Data-Block',
      //     title: 'Server down!',
      //     badge: 'Resolved',
      //     text: 'Region 1: Server crashed!',
      //     time: new Date('11/06/2018'),
      //     status: 'danger',
      //     link: '/dashboard/v3'
      //   }
      // ];
    }
  
    ngOnInit() {
      const user_details = this._shared.getValue('user_details');
      this.user_name = user_details.first_name + ' ' + user_details.last_name;
      this.user_full_name = user_details.full_name;
      console.log(this.user_full_name)
      this.user_email = user_details.user_name;

      this.get_notification();
      this.get_notification_interval();
      this.get_profile_roles();
    }

    ngOnDestroy() {
      clearInterval(this.interval_obj);
    }
  
    get_notification(){
      this.unread_notification = 0;
      this.recent_notifications = [];
      this._commonService.get_unread_push_notifications({}).subscribe(res => {
        if (res.errCode == 0 ) {
          this.notifications = res.msg;
          for (let index = 0; index < this.notifications.length; index++) {
            const element = this.notifications[index];
            if(element.status == "Unread"){
              this.unread_notification +=1 
            }
            if(index<10){
              element.message = this.sanitizer.bypassSecurityTrustHtml(element.message)
              this.recent_notifications.push(element);
            }
          }
        }
      });
    }
    get_notification_interval() {
      this.interval_obj = setInterval(()=>{
        this.get_notification();
      },300000)
    }

    mark_as_read_notfication(id,read_all) {
      const payload = {
        'notification_id_list': [id],
        'read_all':read_all
      };
      this._commonService.mark_read_notifications(payload).subscribe(res => {
        if (res.errCode == 0) {
          for (let i = 0; i < this.recent_notifications.length; i++) {
            if (this.recent_notifications[i].id == id || read_all) {
              this.recent_notifications[i].status = "Read";
              if(!read_all){
                this.unread_notification -= 1;
                break;
              }
            }
          }
          if(read_all){
            this.unread_notification = 0;
          }
        }
      })
    }

    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      if (!state.sidenavOpen && !state.childnavOpen) {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
    }
  
    signout() {
      this.logout();
      this.auth.signout();
    }

    logout() {
      const payload = {}; // will be set in future based on functionality during logging out.
      this.auth.logout(payload).subscribe(res => {
        if (res.errCode === 0) {
          // do nothing for now
        }
      });
    }

    get_profile_roles() {
      this.profiles = this._shared.getValue('user_roles');
      const current_role = this._shared.getValue('current_role');
      console.log(this._shared.getValue('current_role'));
      console.log(this._shared.getValue('current_role'));
      this.current_profile.role_id = current_role.role_id;
      this.current_profile.role_name = current_role.role_name;
    }

    switch_role(role_id){
      this._commonService.get_data_after_refresh({'role_id': role_id}).subscribe(
        res => {
          localStorage.setItem('token', res.token);
          window.location.reload();
        }
      );
    }
}
