<!-- <div class="app-footer"> -->
	<!-- <div class="row">
		<div class="col-md-9">
			<p><strong>Product Name</strong></p>
			<p>A Product by Nyalazone Solutions Pvt. Ltd.</p>
		</div>
	</div> -->
	<!-- <div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
        <a class="btn btn-primary text-white btn-rounded mb-3 mb-sm-0 mr-0 mr-sm-3" 
        href="https://themeforest.net/item/gull-angular-bootstrap-admin-dashboard-template/22866096?ref=mh_rafi" 
        target="_blank">Buy Gull</a>
        <a class="btn btn-warning btn-rounded mb-3 mb-sm-0" 
        href="https://goo.gl/forms/dwQGMJrnk4Q5XEBG3" 
        target="_blank">Feedback</a>
        
		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" src="./assets/images/logo.png" alt="">
			<div>
				<p class="m-0">&copy; 2018 Gull Admin</p>
				<p class="m-0">All rights reserved</p>
			</div>
		</div>
	</div>-->
<!-- </div>  -->
<footer class="page-footer footer font-small app-footer mt-3 p-0">
	<div class="footer-copyright text-center py-3">Copyright © 2020:
		<a href="https://www.nyalazone.com/" target="_blank">Nyalazone</a>
	</div>
	
</footer>