import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor() { }

  get_auth_token() {
  	var token = localStorage.getItem('token');
    return token;
  }
  
  /*get_role_from_local() {
  	var token = localStorage.getItem('current_profile');
    return token;
  }
  
  get_user_id_from_local() {
    var token = localStorage.getItem('user_id');
    return token;
  }
  
  get_person_id_from_local() {
  	var token = localStorage.getItem('person_id');
    return token;
  }*/

  get_local_key(keyname){
    /*
    Gets data from local storgae given key name.
    */
    var data = localStorage.getItem(keyname);
    return data;
  }

}
