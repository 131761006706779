import { Component, OnInit } from '@angular/core';
import { EnvService } from './dce-service/env.service';
import { PlatformLocation } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bootDash';

  constructor(private _env: EnvService, private location: PlatformLocation) {
    location.onPopState((event) => {
      $('.modal').modal('hide');
      $( ".modal-backdrop" ).remove();
      $( ".in" ).remove();
    });
  }

  ngOnInit(){
  	if(this._env.is_prod) {
  		localStorage.removeItem('token');	//	added to log user out on refresh
    }
    if(localStorage.getItem('current_version') != this._env.build_version) {
      localStorage.setItem('current_version', this._env.build_version);
      location.reload(true);
    }
  }

}
