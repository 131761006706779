import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/dce-service/common.service';
import { DatatableWidgetService } from './datatable-widget.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-datatable-widget',
  templateUrl: './datatable-widget.component.html',
  styleUrls: ['./datatable-widget.component.scss']
})
export class DatatableWidgetComponent implements OnInit {

  @Input() case: string;
  
  heading = '';
  global_data_list = [];
  data_list = [];
  datatable_columns =  [];
  service_call_payload: any = {};
  service_name = '';
  selected = [];

  unmodified_url = '';
  datatable_case = {
    'biz_org': {
      'service_call_payload': {
        'columns': []
      },
      'columns':  [
        { prop: 'company_name', name: 'Company Name'},
        { prop: 'trading_name', name: 'Trading Name'},
        { prop: 'registration_number', name: 'Registration Number'},
        { prop: 'registration_date', name: 'Registration Date'},
      ],
      'heading': 'Bus Org',
      'service': 'get_all_bizorg',
      'url': 'busorg/bus-org-details-div/:id'
    },
    'work_flow_object': {
      'service_call_payload': {
        'obj_type': 'rfq_view',
        'status_filter': [],
        'wrkgrp_id': 19
      },
      'columns':  [
        { prop: 'rfq_number', name: 'RFQ #'},
        { prop: 'rfq_table_description', name: 'Description'},
        { prop: 'rfq_table_status', name: 'RFQ Status'},
      ],
      'heading': 'Work Flow Object',
      'service': 'get_workflow_objects_for_wrkgrp',
      'url': ''
    }
  };
  searchControl: FormControl = new FormControl();

  constructor(
    private _commonService: CommonService,
    private _datatableService: DatatableWidgetService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.get_datatable_data();

    this.searchControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe(value => {
      this.filter_data(value);
    });
  }

  update_dt_case() {
    for (const key in this.datatable_case) {
      if (key == this.case) {
        this.service_call_payload = this.datatable_case[key]['service_call_payload'];
        this.service_name = this.datatable_case[key]['service'];
        this.datatable_columns = this.datatable_case[key]['columns'];
        this.heading = this.datatable_case[key]['heading'];
        this.unmodified_url  = this.datatable_case[key]['url'];
        break;
      }
    }
  }

  get_datatable_data() {
    this.update_dt_case();
    this._datatableService.get_datatable_data(this.service_name, this.service_call_payload).subscribe(res => {
      if (res.errCode == 0) {
        this.data_list = res.msg;
        this.global_data_list = res.msg;
      }
    });
  }

  filter_data(val) {
    const data = this.global_data_list;
    const columns = this.datatable_columns.map(col => col.prop );
    const filtered_data = this._commonService.filter_data_in_datatable(val, columns, data);
    this.data_list = [...filtered_data];
  }

  datatable_event(event) {
    switch (event.type) {
      case 'click':
      break;
      case 'dblclick':
        const modified_url = this.url_modification(this.unmodified_url, event.row);
        this._router.navigate(['./' + modified_url]);
      break;
      case 'mouseenter':

      break;
    }
  }

  // url_modification(url, data) {
  //   const url_string = url.toString();
  //   let modified_url = '';
  //   for (let i = 0 ; i < url_string.length - 1; i++) {
  //     if (url_string[i]== '/' && url_string[i+1] == ':') {
  //       let temp_value = '';
  //       modified_url += '/';
  //       for (let j = i + 2; j < url_string.length; j++) {
  //         if (url_string[j] == '/') {
  //           break;
  //         } else {
  //           temp_value += url_string[j];
  //         }
  //         i++;
  //       }
  //       for (const key in data) {
  //         if (key == temp_value) {
  //           modified_url += data[key];
  //           i++;
  //           break;
  //         }
  //       }
  //     } else {
  //       modified_url += url_string[i];
  //     }
  //   }
  //   return modified_url;
  // }

  url_modification(url, data) {
    const variables = url.split('/');
    for (let i = 0; i <  variables.length; i++) {
      if (variables[i][0] == ':') {
        variables[i] = data[variables[i].substring(1)];
      }
    }
    return variables.join('/');
  }
}
