import { Injectable } from '@angular/core';
@Injectable({
providedIn: 'root'
})
export class EnvService {

 public web_page_title = 'ALTERAM CRM';
 public service_host = 'https://altcrm.alteram.co.za';
// public service_host = 'http://35.238.225.235';
 public service_port = 5005;
 public service_url  = this.service_host+':'+this.service_port;
 public raptor_db_host = 'dshb-crm.alteram.co.za';
 public raptor_db_port = 5018;
 public raptor_wh_host = 'dshb-crm.alteram.co.za';
 public raptor_wh_port = 8098;
 public raptor_db_service_url = 'https://' + this.raptor_db_host + ':' + this.raptor_db_port;
 public raptor_wh_service_url = 'https://' + this.raptor_wh_host + ':' + this.raptor_wh_port;
 public is_prod = true;
 public blacklist_file = ['exe','sh']
 public build_version = '1.0.24';
 public skip_spinner_for_services = [];

 public dropdown_list = {
    'communication_type': 160,
   'communication_subtype': 161,
   'division_type': 132,
   'job_title': 158,
   'job_grade': 159,
   'workgroup_type': 162,
   'workgroup_status': 169,
   'business_object': 170,
   'trigger': 171,
   'select_notifications': 172,
   'notification_type': 173,
   'certificates': 163,
   'gender': 49,
   'priority': 164,
   'severity': 165,
   'member_role': 166,
   'contact_role': 167,
   'country': 50,
   'server_type':181,
   'case_status':182,
   'case_resolution_status':183,
   'task_status':184,
   'reporting_role':187,
   'dispatch_business_object': 202,
   'dispatch_trigger': 203,
   'title' : 204,
   'task_dispatch_decision': 206,
   'case_dispatch_decision': 207,
   'workflow_rules_case' : 220,
   'workflow_action_object': 221,
   'workflow_rules_task': 222,
   'workflow_rules_advice': 223,
   'case_channel': 224,
   'query_initiator': 225,
   'id_type': 226,
   'employer_category': 230,
   'all_cases_statuses': 231
   };

   public project_dropdown_list = {
    'hospitals': 214,
    'coverage': 215,
    'day_to_day_cover': 216,
    'day_to_day_extent': 217,
    'adult_dependent': 218,
    'child_dependent': 219
   };


   public services_to_skip = ['/login', '/register','/send_forgot_password_email','/forgot_password','/get_feedback_form_details','/save_feedback','/check_password_already_used'];

   public project_variables = {
     'org_name': 'Customer',
     'org_name_s': 'Customers',
     'show_certification': true,
     'stock_code_label': '',
     'cost_price': 'Cost'
   };

 constructor() { }
 }

 export const web_page_title='ALTERAM CRM';
