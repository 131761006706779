import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import { CommonService } from '../../dce-service/common.service';
import { ToastrService } from 'ngx-toastr';
import { ToastrUtilityService } from '../../dce-service/toastr-utility.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FilterJson } from '../../dce-service/filter_json'
import { AutocompleteComponent } from 'angular-ng-autocomplete/lib/autocomplete/autocomplete.component';
import { EnvService } from 'src/app/dce-service/env.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit,AfterViewInit {
	filter_list:any;
	operator:any;
	@Input() object_type:String;
    @Output() filter_event: EventEmitter<any> = new EventEmitter<any>();
    // public filter_child = {}
    @ViewChildren('autocomplete') autocomplete:QueryList<AutocompleteComponent>;
    autocomplete_obj = {}
    // @ViewChild('creator_full_name', {static: false}) filter_child['creator_full_name'];
    // @ViewChild('prev_owner_full_name', {static: false}) filter_child['creator_full_name'];
	payload :any= {}
	mandatory:any= {}
	form_show = true;
	new_filter_list = [];
	classification_heirarchy = [];
	classification_data = [];
	class_dropdown = []
	classification_root = -1;
	task_function_type_id = -1;
	task_function_subtype_id = -1;

	callService = {
		'get_users_list': async function(self,i){
			await self.commonService.get_users_list({}).toPromise().then(res => {
				if(res.errCode == 0){
					self.filter_list[i]['data'] = res.msg
				}
			});
		},
		'get_user_workgroups':async function(self,i){
			await self.commonService.get_user_workgroups({"wrkgrp_type": self.object_type}).toPromise().then(res => { 
				if (res.errCode == 0) {
					self.filter_list[i]['data'] = res.msg 
				}
			})
		},
		'get_all_biz_org':async function(self,i){
			await self.commonService.get_all_biz_org({"columns": ['id','company_name']}).toPromise().then(res => { 
				if (res.errCode == 0) {
					self.filter_list[i]['data'] = res.msg 
				}
			})
		},
		'get_task_classification_dropdown':async function(self){
			await self.commonService.get_task_classification_dropdown({}).toPromise().then(res => { 
				if (res.errCode == 0) {
					self.classification_heirarchy = res.parent_children_dict;
					self.classification_data = res.msg;
					self.classification_root = res.root_id
					self.populate_next_dropdown(1);
				}
			})
		},
		'get_classification_value_data':async function(self,i){
			let payload = {"classification_type_id":[self.env.dropdown_list.all_cases_statuses],"classification_value_id":[-1]}
			await self.commonService.get_particular_classification_data(payload).toPromise().then(res => { 
				if (res.errCode == 0) {
					self.filter_list[i]['data'] = res.msg[0]['data'] 
				}
			})
		}
	}
	process_and_validate_time(key1,key2){
		let new_payload = {};
		let self = this;
		if(self.payload[key1] != '' && self.payload[key2] != ""){
			let start_date = self.payload[key1] + 'T00:00';
			let end_date = self.payload[key2] + 'T23:59';
			if(start_date >= end_date){
				self._toastr.error('', 'End Date cannot be less than start date', self._toastrUtility.basic_configuration);
				return false;  
			}else{
				new_payload[key1] = start_date
				new_payload[key2] =end_date
			}
		}else{
			if(self.payload[key1] != ''){
				new_payload[key1] = self.payload[key1] + 'T00:00';
			}
			if(self.payload[key2] != ""){
				new_payload[key2] =self.payload[key2] + 'T23:59';
			}
		}	
		return this.process_multiselect(new_payload);	
	}
	process_multiselect(new_payload){
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			if(this.filter_list[i]['type'] == 'multiselect'){
				new_payload[this.filter_list[i].key] = []
				for (var j = this.payload[this.filter_list[i].key].length - 1; j >= 0; j--) {
					let temp = this.payload[this.filter_list[i].key][j]
					new_payload[this.filter_list[i].key].push(temp[this.filter_list[i].data_id_key])
				}
			}
		}
		return new_payload
	}
	validation_function = {
		'case': 
			function(self){
				let new_payload = self.process_and_validate_time('case_createtime','case_lastchange_time');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
		'task':
			function(self){
				let new_payload = self.process_and_validate_time('task_create_time','task_lastchange_time');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
		'advice':
			function(self){
				let new_payload = self.process_and_validate_time('advice_create_time','advice_last_change_time');
				for(let i in self.payload){
					if(new_payload[i] == undefined){
						new_payload[i] = self.payload[i] 	
					}
				}	
				return new_payload
			},
	}
	constructor(
		private _fb: FormBuilder,
		private _fj: FilterJson,
		protected commonService: CommonService,
		protected env: EnvService,
		private _toastr: ToastrService,
		private _toastrUtility: ToastrUtilityService,
	) {

	}

	ngOnInit() {
		this.initializeFilter();
	}
	initializeFilter(){
		this.filter_list = this._fj.filters[this.object_type.toLowerCase()];
		this.operator = this._fj.operator
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			let filter = JSON.parse(JSON.stringify(this.filter_list[i]))
			if(filter['service'] != null){
				this.callService[filter['service']](this,i);
			}
			if(filter['type'] == 'task_function'){
				this.callService['get_task_classification_dropdown'](this);
			}
			if(filter['op']){
				this.operator[filter['key']]['op'] = filter['op']
			}
			this.payload[filter['key']] = filter['default_val'];
			this.mandatory[filter['key']] = filter['mandatory'];
		}
	}
	ngAfterViewInit(){
		let auto_list = this.autocomplete.toArray()
		for (var i = auto_list.length - 1; i >= 0; i--) {
			this.autocomplete_obj[auto_list[i]['elementRef']['nativeElement']['id']] = auto_list[i]
		}
	}
	populate_next_dropdown(level){
	    var sel_id;
	    if(level != undefined){
	      sel_id = this.task_function_type_id;
	    }
	    if(sel_id == -1){
	      sel_id = this.classification_root;
	    }
	    var child_ids = [];
	    switch(level) {
	      case 1:
	      case 2:
	        for(i=level; i<=4; i++){
	          this.class_dropdown['level_'+i] = [];
	        }
	        child_ids = this.classification_heirarchy[sel_id];
	        for(var i=0; i<this.classification_data.length; i++){
	          if($.inArray(this.classification_data[i]['id'], child_ids) !== -1){
	            this.class_dropdown['level_'+level].push(this.classification_data[i]);
	          }
	        }
	        if(this.class_dropdown['level_'+level].length == 1){
	          this.populate_next_dropdown(level+1);
	        }
	        break;
	      }
	}
	set_class(level){
	    for (let index = 0; index < this.class_dropdown['level_'+level].length; index++) {
	      const element = this.class_dropdown['level_'+level][index];
	      if(level == 1 && element.value_value.toLowerCase() == this.payload['task_function_type'].toLowerCase()){
	        this.task_function_type_id = this.class_dropdown['level_'+level][index]['id']
	        break;
	      }else if(level == 2 && element.value_value.toLowerCase() == this.payload['task_function_type'].toLowerCase()){
	        this.task_function_subtype_id = this.class_dropdown['level_'+level][index]['id']
	        break;
	      }
	    }
	}
	completer_select(event,filter_key,data_key){
		this.payload[filter_key] = event[data_key]
	}
	onItemSelect(item: any) {

	}
	process_payload(payload){
		if(payload){
			let sent_payload = {};
			for (var i in payload) {
			  if((i != "prev_wrkgrp_id" && payload[i] != "") || (i == "prev_wrkgrp_id" && payload[i].length >0)){
			    sent_payload[i] = {
			       'operator': this.operator[i].op,
			       'data': payload[i],
			       'type': this.operator[i].type
			     }
			  }
			}
			// sent_payload["wfobj_status"] = {
			//    'operator': "==",
			//    'data': "Closed",
			//    'type': "str"
			// }
			return sent_payload;
		}else{
			return false;
		}
	}
	validate_payload(){
		let sent_payload = {};
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			let filter = this.filter_list[i]
			if(filter['type'] == 'autocomplete' && this.autocomplete_obj[filter['key']]['query'] == ""){
				this.payload[filter['key']] = ''
			}
			
			if(filter['mandatory']){
				if(this.payload[filter['key']] == ''){
          			this._toastr.warning('', 'Enter '+filter['label'], this._toastrUtility.basic_configuration);
					return false;
				}
			}
		}
		let _object_type = this.object_type.toLowerCase()
		sent_payload = this.process_payload(this.validation_function[_object_type](this));
		if(Object.keys(sent_payload).length > 0){
			this.filter_event.emit({'case': 'search', 'data': sent_payload });
			this.form_show=false;
		}else{
			this._toastr.error('', 'Fill atleast 1 Filter', this._toastrUtility.basic_configuration);

		}
	}

	reset_payload(){
		for (var i = this.filter_list.length - 1; i >= 0; i--) {
			let filter = this.filter_list[i]
			this.payload[filter['key']] = '';
			if(filter['type'] == 'autocomplete'){
				this.autocomplete_obj[filter['key']].clear();
				this.autocomplete_obj[filter['key']].close();
			}
		}
		this.filter_event.emit({'case': 'reset', 'data': {} });
	}

}
