import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../dce-service/env.service';

@Injectable({
	providedIn: "root"
})
export class AddLogsService {

	constructor(
		private env: EnvService,
		private http: HttpClient,
		private _router: Router
	) {}

	// 'add_case_notes',  'send_case_log_email'
	private _send_data_url = `${this.env.service_url}/`;

	send_data(service, payload) {
		return this.http.post<any>(this._send_data_url + service, payload)
	}

}
