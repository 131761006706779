import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
import { TreeService } from '../tree/tree.service';
import { SharedService } from '../../dce-service/shared.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class TreeComponent implements OnInit, AfterViewInit, OnChanges {

	//	Variables Initialised

  	@Input() case: string;
  	@Input() tree_id: string;
  	@Input() id: string;
    @Input() search_placeholder: string = "Search within Hierarchy";

    @Output() tree_event: EventEmitter<any> = new EventEmitter<any>();
    // @ViewChild('mainScreen') elementView: ElementRef;

  	menu_map = {

      "busorgdetails": {
        "menu": {
          "biz_org": {
            "4__4001": "add_new_division",/*add_new_division*/
          },
          "div_org": {
            "36__44005": "",/*add_new_division*/
            "36__44006": "",/*remove_division*/
          }
        },
        "service": "get_tree_biz",
      },

      "busorgdetails_division": {
        "menu": {
          "biz_org": {
            "36__44001": "",/*add_new_division*/
          },
          "div_org": {
            "7__7001": "add_new_division",/*add_new_division*/
            "7__7002": "remove_division",/*remove_division*/
          },
          "no_context": {}
        },
        "service": "get_tree_biz",
      },

      "busorgdetails_employee": {
        "menu": {
          "biz_org": {
            "36__44001": "",/*add_new_division*/
          },
          "div_org": {
            "36__44005": "",/*add_new_division*/
            "36__44006": "",/*remove_division*/
          }
        },
        "service": "get_tree_biz",
      },

      'sitedetails' : {
        "menu": {
          "biz_org": {
            "14__14001": "add_new_site",
            "36__44002": "",/*add_contact*/
          },
          "site_leaf": {
            "36__44005": "",/*add_new_site*/
            "36__44006": "",/*add_contact*/
          },
          "site_non_leaf": {
            "36__44005": "",/*add_new_site*/
            "36__44006": "",/*add_contact*/
          },
          "contact_rec": {
            "36__44005": "",/*add_new_site*/
            "36__44006": "",/*add_contact*/
          }
        },
        "service": "get_tree_biz_site",
      },

      'sitedetails_location' : {
        "menu": {
          "biz_org": {
            "36__44001": ""
          },
          "site_leaf": {
            "17__17001": "add_new_site",
            "17__17002": "remove_site",
            "17__17003": "add_contact"
          },
          "site_non_leaf": {
            "17__17002": "remove_site",
            "17__17001": "add_new_site"
          },
          "contact_rec": {}
        },
        "service": "get_tree_biz_site",
      },
      'sitedetails_contact' : {
        "menu": {
          "biz_org": {
            "36__44001": ""
          },
          "site_leaf": {
            "36__44006": ""
          },
          "site_non_leaf": {
            "36__44006": ""
          },
          "contact_rec": {}
        },
        "service": "get_tree_biz_site",
      },
      "employeedetails": {
        "menu": {
          "biz_org": {
           "38__46001" : "",/*add_new_division*/
          },
          "div_org": {
            "38__46004" : "",/*add_new_division*/
            "38__46005" : "",/*remove_division*/
          }
        },
        "service": "get_tree_biz",
      },

      "classification": {
        "menu": {
          "root_class": {
            "26__26001":"add_sub_classification"
          },
          "sub_class": {
            "26__26001" : "add_sub_classification",
            "26__26002" : "remove_classification"
          }
        },
        "service": "get_classification_tree",
      },
      

      "discipline_classification": {
        "menu": {
          "root_class": [],
          "sub_class": [],
          "leaf_class": []
        },
        "service": "get_internal_class_priority_severity",
      },
      
      'asidetails_division' : {
        "menu": {
          "asi_biz": {
            "37__37001":"add_new_department"
          },
          "asi_div": {
            "37__37001":"add_new_department",
            "37__37002": "remove_department"
          },
          "asi_div_leaf": {
            "37__37001":"add_new_department",
            "37__37002":"remove_department", 
            "37__37003":"add_new_employee"
          },
          "asi_emp": []
        },
        "service": "get_internal_org_employee_tree"
      },
      'asidetails_employee' : {
        "menu": {
          "asi_biz": [],
          "asi_div": [],
          "asi_div_leaf": [""],
          "asi_emp": [""]
        },
        "service": "get_internal_org_employee_tree"
      },
      "bizorg_productmap": {
        "menu": {
          'biz_org':{
            "41_00001":""
          },
          "product_type": {
            "41_00001":""
          },
          "product_house": {
            "41_10001": "",
          },
          "product": {
            "41__10002": "remove_prod",
          }
        },
        "service": "get_biz_prod_tree",
      },
  	}
    
    context_menu_items_map = {
      "add_new_site": "Add New Location",
      "remove_site": "Remove Location",
      "add_contact": "Add New Contact",
      "add_new_division": "Add New Division",
      "remove_division": "Remove Division",
      "add_new_department": "Add New Department",
      "remove_department": "Remove Department",
      "add_sub_classification": "Add Sub Classification",
      "remove_classification": "Remove Classification",
      "add_new_employee": "Add New Employee",
      "remove_employee": "Remove Employee",
      "update_employee": "Update Employee",
      "remove_prod_house": "Remove Product House",
      "remove_prod": "Remove Product",
    }

  	menu_items = {};
  	service = '';

		icon_types: any = {
			"default" : {
				"icon" : "jstree-remove-icon"
			},
      "biz_org" : {
        "icon" : "jstree-remove-icon"
      },
      "div_org" : {
        "icon" : "jstree-remove-icon"
      },
			"root_class" : {
				"icon" : "jstree-remove-icon"
			},
			"sub_class" : {
				"icon" : "jstree-remove-icon"
			},
      "asi_biz" : {
        "icon" : "i-Building"
      },
      "asi_div" : {
        "icon" : "i-Conference"
      },
      "asi_div_leaf" : {
        "icon" : "i-Conference"
      },
      "asi_emp" : {
        "icon" : "i-Administrator"
      },
      "site_leaf" : {
        "icon" : "i-Map-Marker"
      },
      "site_non_leaf" : {
        "icon" : "i-Map-Marker"
      },
      "contact_rec" : {
        "icon" : "i-Administrator"
      },
      "product_house" : {
        "icon" : "jstree-remove-icon"
      },
      "product" : {
        "icon" : "jstree-remove-icon"
      },
		}

    tree_data = [];
    tree_height = '';
    role_map = {};

	constructor(
		private _sharedService: SharedService,
		private _tree_service: TreeService
		) {

  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.id.firstChange) {
      this._update_tree_data(this.case, this.tree_id, changes.id.currentValue);
    }
  }

	ngOnInit() {
		var self = this;
    this.role_map = this._sharedService.get_roles_map();
    this._get_tree_data();
  }

  ngAfterViewInit() {
    this.onResize('');
  }

  // @HostListener('window:scroll', [])
  // onScroll(event): void {
  //   localStorage.setItem(this.tree_id, event.srcElement.scrollTop);
  // }

  onResize(event) {
    // 20 value for the margin bottom 
    if ($('.top-position').offset() != undefined) {
      const w = window.innerHeight - $('.top-position').offset().top - 20;
      const divHeight = w.toString() + 'px' ;
      document.getElementsByClassName('top-position')[0].setAttribute('style', `height:${divHeight}`);
    }
  }

	jsTree(tree_id, tree_data){

    var to: any = false;

    $('#search_'+tree_id).keyup(function() {
      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(function() {
        var v = $('#search_'+tree_id).val();
        $('#'+tree_id).jstree(true).search(v);
      }, 250);
    });

    tree_id = tree_id;
    // tree_id = 'js_tree_id';
		var self = this;
		// alert(self);
		// console.log(tree_data);
		var main_tree = $('#'+tree_id).jstree({
			'core' : {
				'data' : tree_data,
				'themes': {
          'name': 'proton',
          'responsive': true,
					'icons': true
				}
			},
      "plugins": ["types", "contextmenu", "search"],
      "search": {
        "show_only_matches": true,
        "show_only_matches_children": true,
      },
			// "plugins": ["contextmenu"],
      "types" : this.icon_types,
			"contextmenu": {
		    "items": function(node) {
          console.log(node)
          return self.context_menu_options(self, node);
        }
	    }

		}).bind("select_node.jstree", function (evt, data){
			self.left_click_options(self, data);
    });

    this.tree_event.emit({'case': 'tree_loaded', 'data': {} });

  }

  //	FUNCTIONS

  context_menu_options(self, node){
  	// var tree = $("#"+tree_id).jstree(true);	//	IMPORTANT

    var final_items = {};

    var items = {};
    for(var item in this.context_menu_items_map){
      items[item] = {
        label: this.context_menu_items_map[item],
        value: item,
        action: function (data) {
          self.tree_to_parent({'case': data.item.value, 'data': node.original});
        }
      };
    }

    for(var i=0; i<node.data.context_items.length; i++) {
    	final_items[node.data.context_items[i]] = items[node.data.context_items[i]];
    }

    return final_items;

  }

  left_click_options(self, data){
    console.log(data);
    var internal_case = data.node.original.type;
    self.tree_to_parent({'case': self.case + '_left_' + internal_case , 'data': data.node.original, 'level':data.node.parents.length});

  }

  tree_to_parent(data: any): void {
      this.tree_event.emit(data);
  }

  _get_tree_data() {
  	this.update_menu_items();

    this._tree_service.get_tree_data(this.service, this.id, this.menu_items).subscribe(
      res => {
        this.tree_data = res;
        this.jsTree(this.tree_id, this.tree_data);
      },
      err => {
        console.log(err)
      }
    );

  }
  _get_tree_length(){
    this.tree_to_parent({'case':'tree_length','data':this.tree_data.length});   
  }
  _update_tree_data(case_type, tree_id, id) {
  	this.case = case_type;
  	this.tree_id = tree_id;
  	this.id = id;

  	this.update_menu_items();

	    this._tree_service.get_tree_data(this.service, this.id, this.menu_items).subscribe(
	      res => {
	        this.tree_data = res;
	        // console.log(this.tree_data);
	        // console.log($('#'+ this.tree_id).jstree(true));
	        $('#'+ this.tree_id).jstree(true).settings.core.data = this.tree_data;
					$('#'+ this.tree_id).jstree(true).refresh();
	      },
	      err => {
	        console.log(err)
	      }
	    );

  }

  update_menu_items(){
  	this.menu_items = this.filter_menu_by_role(this.menu_map[this.case]['menu']);
  	this.service = this.menu_map[this.case]['service'];

  }

  filter_menu_by_role(menu_items){
    // return menu_items;
    var return_menu_obj = {};
    for (var type in menu_items) {
      return_menu_obj[type] = [];
      for (var role_key in menu_items[type]) {
        if(this.fetch_role(role_key) == 1){
          return_menu_obj[type].push(menu_items[type][role_key]);
        } else {
          // skip adding to menu
        }
      }
    }

    return return_menu_obj;
  }

  fetch_role(key){
    var param = key.split("__");
    if(this.role_map[param[0]] != undefined && this.role_map[param[0]][param[1]] != undefined){
      return this.role_map[param[0]][param[1]];
    } else {
      return 1;
    }
  }

  update_tree_data_local() {
    $('#'+ this.tree_id).jstree(true).settings.core.data = this.tree_data;
    $('#'+ this.tree_id).jstree(true).refresh();
  }

  add_new_node_local(object){
    // add new node to this.tree_data
    object['data'] = {"context_items": this.menu_map[this.case]['menu'][object.type]};
    object['state'] = {"opened": true};
    this.tree_data.push(object);
    this.update_tree_data_local();
  }

  // update_node_local(id, object){
  //   // update node in this.tree_data
  //   for(var i=0; i<this.tree_data.length; i++){
  //     if(this.tree_data[i]['id'] == id){
  //       object['data'] = {"context_items": this.menu_map[this.case]['menu'][object.type]};
  //       this.tree_data[i] = object;
  //     }
  //   }
  //   this.update_tree_data_local();
  // }
  
  update_node_text_local(id, text){
    // update node in this.tree_data
    for(var i=0; i<this.tree_data.length; i++){
      if(this.tree_data[i]['id'] == id){
        this.tree_data[i]['text'] = text;
        break;
      }
    }
    this.update_tree_data_local();
  }

  remove_node_local(id){
    this.remove_nodes_recursive(id);
    this.update_tree_data_local();
  }

  remove_nodes_recursive(id){
    var children = [];
    var index_to_remove = -1;
    for(var i=0; i<this.tree_data.length; i++){
      if(this.tree_data[i]['id'] == id){
        index_to_remove = i;
      }
      if(this.tree_data[i]['parent'] == id){
        children.push(this.tree_data[i]['id']);
      }
    }
    if(children.length){
      for(var j=0; j<children.length; j++){
        this.remove_nodes_recursive(children[j]);
      }
    }

    this.tree_data.splice(index_to_remove, 1);

  }

  select_node(id) {
    setTimeout( () => { 
      $('#' + this.tree_id).jstree('select_node', id);
      // $('#' + this.tree_id).animate( {scrollTop : localStorage.getItem(this.tree_id) }, 600 )
    }, 500);
  }

  get_node_name(id,_case) {
    setTimeout( () => {
      var node = $('#' + this.tree_id).jstree().get_node(id).text; 
      this.tree_to_parent({'case':_case,'data':node});   
    },500);
  }

}
